import { ComponentType } from 'react';
import { INavigation, NavigationEnum } from 'src/types/INavigation';
import {
    ItemContainer,
    ItemLink,
    ItemName,
    NavigationItemWrapper
} from './NavigationItem.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'src/providers/RootStoreProvider';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';

//components

//styles

interface Props {
    data: INavigation;
}

export const NavigationItem: ComponentType<Props> = observer(({ data }) => {
    const store = useRootStore();
    const navigate = useNavigate();
    const { indexMainMenu, changeMainMenuIndex } = store.globalStore;
    const { currentNetStation } = store.netStationStore;

    const active = data.id === indexMainMenu;

    const onClick = () => {
        if (
            data.id === NavigationEnum.Survey ||
            data.id === NavigationEnum.Visit ||
            data.id === NavigationEnum.Result
        ) {
            if (!currentNetStation || currentNetStation.netId === 0) {
                Modal.error({
                    title: 'Không hợp lệ',
                    content:
                        'Bạn phải chọn hoặc tạo mới phòng máy trước khi tiếp tục',
                    centered: true
                });
                return;
            }
        }
        navigate(data.link);
        changeMainMenuIndex(data.id);
    };

    return (
        // <ItemLink to={data.link}>
        //     <NavigationItemWrapper
        //         className={active ? 'active' : 'inactive'}
        //         onClick={onClick}>
        //         {data.icon}
        //         <ItemName>{data.title}</ItemName>
        //     </NavigationItemWrapper>
        // </ItemLink>
        <ItemContainer>
            <NavigationItemWrapper
                className={active ? 'active' : 'inactive'}
                onClick={onClick}>
                {data.icon}
                <ItemName>{data.title}</ItemName>
            </NavigationItemWrapper>
        </ItemContainer>
    );
});
