export const API_ = {
    AUTHENTICATION: {
        LOGIN: 'login',
        FORGOT_PASS: 'login/forgetpwd',
        CHANGE_PASS: 'login/changepwd',
        GET_TOKEN: 'login/auth2'
    },
    USER: {
        PROFILE_DETAIL: 'user-detail',
        FEEDBACK: 'feedback',
        ADD_GAME: 'request-game'
    },

    NET_STATION: {
        LIST: 'list-netstation',
        INFO: 'net-station/info',
        UPDATE_INFO: 'net-station/info/create',
        PHOTO: 'net-station/photo',
        VISIT_STATUS: 'net-station/visit-status',
        UPDATE_RESULT_VISIT: 'net-station/visit-status-create',
        UPDATE_PHOTO: 'net-station/photo/create',
        SURVEY: 'net-station/survey',
        UPDATE_SURVEY: 'net-station/survey/create',
        CITY: 'net-station/city',
        DISTRICT: 'net-station/district'
    },
    HISTORY: {
        LIST: 'history',
        PHOTO: 'history-photo',
        SALE_RESULT: 'history-visit-result',
        SURVEY: 'history-visit-survey'
    },
    GLOBAL: {
        PRODUCT: 'product',
        UPLOAD_IMAGE: 'webhook/upload'
    },
    JSON: {}
};

export const API = {
    AUTHENTICATION: {
        LOGIN: 'login',
        FORGOT_PASS: 'login/forgetpwd',
        CHANGE_PASS: 'login/changepwd',
        GET_TOKEN: 'login/auth2'
    },
    USER: {
        PROFILE_DETAIL: 'user-detail',
        FEEDBACK: 'feedback',
        ADD_GAME: 'request-game'
    },

    NET_STATION: {
        LIST: 'net-station/list',
        INFO: 'net-station/info',
        UPDATE_INFO: 'net-station/info/create',
        PHOTO: 'net-station/photo',
        VISIT_STATUS: 'net-station/visit-status',
        UPDATE_RESULT_VISIT: 'net-station/visit-status-create',
        UPDATE_PHOTO: 'net-station/photo/create',
        SURVEY: 'net-station/survey',
        UPDATE_SURVEY: 'net-station/survey/create',
        CITY: 'net-station/city',
        DISTRICT: 'net-station/district',
        PHOTO_LICENSE: '/net-station/info/photo-business-license',
        DELETE_LICENSE: 'net-station/info/photo-delete-business-license'
    },
    HISTORY: {
        LIST: 'net-station/history',
        PHOTO: 'net-station/history-photo',
        SALE_RESULT: 'net-station/history-visit-result',
        SURVEY: 'net-station/history-visit-survey'
    },
    GLOBAL: {
        PRODUCT: 'product',
        UPLOAD_IMAGE: 'webhook/upload'
    },
    JSON: {}
};
