import styled from 'styled-components';

export const VisitInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 150px;
    width: 100%;
`;

export const VisitTitle = styled.p`
    font-weight: 600;
    font-size: 18px;
`;

export const InfoContainer = styled.div`
    padding-bottom: 70px;
    margin: 10px;
`;

export const UploadContainer = styled.div`
    margin: 10px;
`;

export const ActionWrapper = styled.div`
    position: fixed;
    bottom: 0;
    padding: 5px;
    height: 70px;
    background-color: #f7f7f2;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: solid 1px #c7c6bf;
`;

export const PhotoDescription = styled.p`
    font-size: 12px;
    font-weight: 400;

    color: #7f8280;
`;
