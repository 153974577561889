import { LOCAL_KEY } from './../configs/AppKey';
import { observable, makeObservable, action, runInAction } from 'mobx';
import AuthService from '../services/AuthService';
import RootStore from './RootStore';
import {
    IChangePassRequest,
    IForgetPassRequest,
    IGetTokenRequest,
    ILoginResponse
} from '../types/ILogin';
import { APIResponse } from '../models/APIResponse';
import {
    ILoginResponseBase,
    IRequestBase,
    IResponseBase
} from '../types/ITypeBase';
import { IGetUserProfileDetailRequest, IUser } from '../types/IUser';
import User from '../models/User';
import UserService from '../services/UserService';
class AuthStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable currentUser: User | null = null;
    @observable loadingLogin: boolean = false;

    //declare action
    @action login = async (params: IRequestBase) => {
        this.loadingLogin = true;
        const result = (await AuthService.getInstance().login(
            params
        )) as ILoginResponseBase;
        if (result && result.isSuccess()) {
            const userToken: ILoginResponse = {
                userId: result.data?.userId,
                ssId: result.data?.ssid,
                accessToken: result.data!
            };
            localStorage.setItem(
                LOCAL_KEY.CURRENT_USER,
                JSON.stringify(userToken)
            );
            runInAction(() => {
                this.currentUser = User.fromJson(result.data);
            });
        }
        this.loadingLogin = false;
        return result;
    };

    @action logout = async () => {
        localStorage.removeItem(LOCAL_KEY.CURRENT_USER);
        runInAction(() => {
            this.currentUser = null;
        });
    };

    @action setUserData = async (data: any) => {
        runInAction(() => {
            this.currentUser = data;
        });
    };

    forgetPass = async (params: IForgetPassRequest) => {
        const result = (await AuthService.getInstance().forgetPass(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    getToken = async (params: IGetTokenRequest) => {
        const result = (await AuthService.getInstance().getToken(
            params
        )) as ILoginResponseBase;
        if (result && result.isSuccess()) {
            const userToken: ILoginResponse = {
                userId: result.data?.userId,
                ssId: result.data?.ssid,
                accessToken: result.data.accessToken!
            };
            localStorage.setItem(
                LOCAL_KEY.CURRENT_USER,
                JSON.stringify(userToken)
            );
            runInAction(() => {
                this.currentUser = User.fromJson(result.data);
            });
        }
        return result;
    };

    changePass = async (params: IChangePassRequest) => {
        const result = (await AuthService.getInstance().changePass(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    @action getProfileGoogle = async (jwt: string) => {};

    //declare actions
    //get profile user and return an APIResponse with data type is IUser
    @action getProfileDetail = async (params: IGetUserProfileDetailRequest) => {
        const result = (await UserService.getInstance().getProfileDetail(
            params
        )) as APIResponse<IUser>;

        if (result && result.isSuccess())
            runInAction(() => {
                //convert json response to User object
                this.currentUser = User.fromJson(result.data!);
            });
        return result;
    };
}
export default AuthStore;
