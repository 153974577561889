import { Route, Routes, Navigate } from 'react-router-dom';

//import views
import { LoginView } from '../views/LoginView/LoginView';
import { TicketView } from '../views/TicketView/TicketView';
import { SurveyView } from 'src/views/SurveyView';
import { NetList } from 'src/views/NetList';
import { NetInfo } from 'src/views/NetInfo';
import { LocationDenied } from 'src/views/LocationDenied';

//import layouts
import MainLayout from '../layouts/MainLayout/MainLayout';
import { VisitInfo } from 'src/views/VisitInfo';
import ScrollToTop from 'src/components/ScrollTop';
import { SaleResult } from 'src/views/SaleResult';
import HistoryView from 'src/views/HistoryView';
import HistoryDetail from 'src/views/HistoryDetail';

const AppRouters = () => {
    return (
        <ScrollToTop>
            <Routes>
                <Route element={<MainLayout />}>
                    <Route path="*" element={<SurveyView />} />
                    <Route path="/survey" element={<SurveyView />} />
                    <Route path="/history" element={<HistoryView />} />
                    <Route path="/history-detail" element={<HistoryDetail />} />
                    <Route path="/denied" element={<LocationDenied />} />
                    <Route path="/ticket" element={<TicketView />} />
                    <Route path="/visit" element={<VisitInfo />} />
                    <Route path="/result" element={<SaleResult />} />
                    <Route path="/info" element={<NetInfo />} />
                    <Route path="/net/list" element={<NetList />} />
                </Route>

                <Route path="/login" element={<LoginView />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </ScrollToTop>
    );
};

export default AppRouters;
