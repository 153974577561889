import {
    IGetListNetStationRequest,
    IGetNetStationInfoRequest,
    IUpdateNetStationRequest
} from 'src/types/INetStation';
import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';

import BaseAuthService from './BaseAuthService';
import {
    IGetListCityRequest,
    IGetListDistrictRequest
} from 'src/types/IGlobal';

export class NetStationService extends BaseAuthService {
    private static instance: NetStationService;

    public static getInstance(): NetStationService {
        if (!NetStationService.instance) {
            NetStationService.instance = new NetStationService();
        }
        return NetStationService.instance;
    }

    getListNetStation = async (params: IGetListNetStationRequest) => {
        try {
            const response = await this.postData(API.NET_STATION.LIST, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getNetStationInfo = async (params: IGetNetStationInfoRequest) => {
        try {
            const response = await this.postData(API.NET_STATION.INFO, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    updateNetStationInfo = async (params: IUpdateNetStationRequest) => {
        try {
            const response = await this.postData(
                API.NET_STATION.UPDATE_INFO,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListCity = async (params: IGetListCityRequest) => {
        try {
            const response = await this.postData(API.NET_STATION.CITY, params);
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListDistrict = async (params: IGetListDistrictRequest) => {
        try {
            const response = await this.postData(
                API.NET_STATION.DISTRICT,
                params
            );
            return this.handleResponseList(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
