import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';
import { SaleResultWrapper } from './index.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'src/providers/RootStoreProvider';
import {
    IGetListVisitResultRequest,
    IResultVisit,
    IUpdateResultVisitRequest
} from 'src/types/IVisit';
import { QuestionItem } from './QuestionItem';
import { Button, Modal, Spin } from 'antd';
import { ActionWrapper } from '../SurveyView/index.styles';
import { showMessage } from 'src/utils';
import _ from 'lodash';
import { NavigationEnum } from 'src/types/INavigation';

//components

//styles

interface Props {}

export const SaleResult: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { setAppTitle, changeMainMenuIndex } = store.globalStore;
    const { currentNetStation } = store.netStationStore;
    const {
        listVisitResult,
        getListVisitResult,
        isDoneVisitResult,
        submitResultVisit
    } = store.surveyStore;
    const [loading, setLoading] = useState(false);
    const [loadingAction, setLoadingAction] = useState<boolean>(false);

    useEffect(() => {
        onGetSaleResult();
        setAppTitle(currentNetStation?.netName!);
        changeMainMenuIndex(NavigationEnum.Result);
    }, [currentNetStation]);

    const onGetSaleResult = async () => {
        try {
            setLoading(true);
            const params: IGetListVisitResultRequest = {
                data: {
                    netId: currentNetStation?.netId!
                }
            };
            await getListVisitResult(params);
            setLoading(false);
        } catch (error: any) {
            Modal.error({
                title: 'Lỗi xảy ra',
                content: error.message,
                centered: true
            });
        } finally {
            setLoading(false);
        }
    };

    const onCheckResult = async () => {
        try {
            setLoadingAction(true);
            if (isDoneVisitResult() && listVisitResult) {
                const noteObj = _.find(
                    listVisitResult,
                    (item: IResultVisit) => {
                        return Number(item.answerType) === 1;
                    }
                );

                const selectedObj = _.find(
                    listVisitResult,
                    (item: IResultVisit) => {
                        return Number(item.selected) === 1;
                    }
                );

                const params: IUpdateResultVisitRequest = {
                    data: {
                        netId: currentNetStation?.netId!,
                        note: noteObj ? noteObj.extInput : '',
                        resultId: selectedObj?.statusId!
                    }
                };
                const result = await submitResultVisit(params);
                setLoadingAction(false);
                showMessage(result);
            } else {
                Modal.error({
                    title: 'Không hợp lệ',
                    content: 'Bạn chưa trả lời hết các câu hỏi',
                    centered: true
                });
            }
        } catch (error: any) {
            Modal.error({
                title: 'Lỗi xảy ra',
                content: error.message,
                centered: true
            });
        } finally {
            setLoadingAction(false);
        }
    };

    return (
        <SaleResultWrapper>
            {loading ? (
                <Spin />
            ) : (
                <>
                    {listVisitResult ? (
                        <QuestionItem data={listVisitResult} />
                    ) : null}

                    <ActionWrapper>
                        <Button
                            type="primary"
                            loading={loadingAction}
                            style={{ width: '100%' }}
                            htmlType="button"
                            onClick={onCheckResult}>
                            Cập nhật kết quả
                        </Button>
                    </ActionWrapper>
                </>
            )}
        </SaleResultWrapper>
    );
});
