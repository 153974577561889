import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface TabItemProps {
    active1: string;
}
export const NavigationItemWrapper = styled.div.attrs(
    (props: TabItemProps) => ({
        className: props.active1
    })
)`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    //border-radius: 10px;

    &.active {
        border-bottom: solid 5px #148ade;
    }
    &.inactive {
        border: none;
    }
`;

export const ItemName = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    margin-top: 6px;
`;

export const ItemLink = styled(Link)`
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 80px;
    color: #8b8c8f;
    :active {
        color: #148ade;
    }
    :hover {
        color: #148ade;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    width: 100%;
    height: 80px;
    color: #8b8c8f;
    cursor: pointer;
`;
