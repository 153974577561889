import { ComponentType, useEffect, useState } from 'react';
import {
    Form,
    Select,
    Button,
    Input,
    Spin,
    Collapse,
    Modal,
    Upload,
    List,
    UploadFile
} from 'antd';
import {
    IGetNetStationInfoRequest,
    INetStation,
    IPhotoLicense,
    IRemoveLicenseRequest,
    IUpdateNetStationRequest
} from 'src/types/INetStation';
import {
    ActionWrapper,
    InfoContainer,
    NetInfoWrapper,
    PanelCustom,
    PhotoDescription,
    UploadContainer
} from './index.styles';

import { observer } from 'mobx-react-lite';
import { useRootStore } from 'src/providers/RootStoreProvider';
import { useForm } from 'antd/lib/form/Form';
import { setPropertyValue, showMessage } from 'src/utils';
import { IGetListDistrictRequest } from 'src/types/IGlobal';
import { NavigationEnum } from 'src/types/INavigation';
import { useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { DialogPreview } from '../VisitInfo/DialogPreview';
//components

//styles

interface Props {}

const { Option } = Select;
const { Panel } = Collapse;

export const NetInfo: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingAction, setLoadingAction] = useState<boolean>(false);

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [fileContent, setFileContent] = useState('');
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

    const {
        listCity,
        listDistrict,
        getListDistrict,
        currentNetStation,
        setNetStationData,
        getNetStationInfo,
        updateNetStation,
        uploadLicense,
        deleteLicense
    } = store.netStationStore;
    const { changeMainMenuIndex, setAppTitle } = store.globalStore;

    const [form] = useForm();

    const onFinish = async (values: INetStation) => {
        console.log('values', values);

        try {
            setLoadingAction(true);
            values.netId = currentNetStation?.netId!;
            console.log('Received values:', values);
            const params: IUpdateNetStationRequest = {
                data: values
            };
            const result = await updateNetStation(params);
            if (result && result.isSuccess()) {
                setNetStationData(result.data);

                if (fileList && fileList.length > 0) {
                    const data: any = result.data;
                    console.log('Updated values:', data.netId);

                    setLoadingAction(true);
                    const formData = new FormData();

                    fileList.forEach((file) => {
                        if (file.originFileObj)
                            formData.append('files', file.originFileObj!);
                    });
                    formData.append('netId', data.netId);
                    const resultUpload = await uploadLicense(formData);
                    if (!resultUpload || resultUpload.isError()) {
                        showMessage(resultUpload);
                        setLoadingAction(false);
                    }
                }
            }
            setLoadingAction(false);
            showMessage(result);
        } catch (error: any) {
        } finally {
            setLoadingAction(false);
        }
    };

    const onFinishFailed = () => {
        Modal.error({
            title: 'Không hợp lệ',
            content: 'Vui lòng kiểm tra và nhập đầy đủ các thông tin',
            centered: true
        });
    };

    const onGetNetStationInfo = async () => {
        setLoading(true);
        const params: IGetNetStationInfoRequest = {
            data: {
                netId: currentNetStation?.netId!
            }
        };
        await getNetStationInfo(params);
        setLoading(false);
    };

    const onGetDistrictByCity = (cityId: any) => {
        const params: IGetListDistrictRequest = {
            data: {
                cityId
            }
        };
        getListDistrict(params);
    };

    const onCityChange = (e: any) => {
        if (!e) return;
        setPropertyValue(currentNetStation, 'cityId', e);
        onGetDistrictByCity(e);
    };

    useEffect(() => {
        changeMainMenuIndex(NavigationEnum.Info);
        if (currentNetStation) {
            if (currentNetStation.netId !== 0) {
                onGetNetStationInfo();
            }
        } else navigate('/net/list');
    }, []);

    useEffect(() => {
        onGetDistrictByCity(currentNetStation?.cityId);
    }, [currentNetStation?.cityId]);

    useEffect(() => {
        setAppTitle(currentNetStation?.netName!);
        form.setFieldsValue(currentNetStation);
    }, [currentNetStation]);

    const validateNumber = (
        _: unknown,
        value: string,
        callback: (error?: string) => void
    ) => {
        const numericPattern = /^[0-9]*$/;
        if (!numericPattern.test(value)) {
            callback('Số lượng máy trạm phải là chữ số ');
        } else {
            callback();
        }
    };

    const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
        // Ẩn tên của các tệp
        const updatedFileList = fileList.map((file) => ({
            ...file,
            name: ''
        }));
        setFileList(updatedFileList);
    };

    const handlePreview = async (file: UploadFile) => {
        console.log(file);
        if (!file.url && !file.preview && file.originFileObj) {
            const fileContent = await getBase64(file.originFileObj);
            setFileContent(fileContent);
        } else setFileContent(file.url!);
        setOpenPreviewDialog(true);
    };

    const handleRemove = async (file: UploadFile) => {
        console.log('Removing file', file);
       
        if (!file.originFileObj) {
            const removeFile = {
                uid: file.uid,
                imgName: file.url!.substring(file.url!.lastIndexOf('/') + 1)
            };
            const params:IRemoveLicenseRequest = {
                data: removeFile
            }
            const result = await deleteLicense(params);
            if (result && result.isSuccess()) {
                setFileList((prevFileList) =>
                    prevFileList.filter((item) => item !== file)
                );
            }
            showMessage(result);
        }
        console.log('Removing', fileList);
    };

    const getBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    };

    const uploadButton = (
        <div>
            <UploadOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const uploadPhotoLicense = async (netId: any) => {
        try {
            console.log('fileList', fileList);
            setLoadingAction(true);
            const formData = new FormData();

            fileList.forEach((file) => {
                formData.append('files', file.originFileObj!);
            });
            formData.append('netId', netId);
            console.log('formData', formData);
            const result = await uploadLicense(formData);
            setLoadingAction(false);
            showMessage(result);
        } catch (error: any) {
            Modal.error({
                title: 'Lỗi xảy ra',
                content: error.message,
                centered: true
            });
        } finally {
            setLoadingAction(false);
        }
    };

    useEffect(() => {
        if (
            currentNetStation &&
            fileList.length === 0 &&
            currentNetStation.images &&
            currentNetStation.images.length > 0
        ) {
            const files: UploadFile[] = [];
            currentNetStation.images?.forEach((item) => {
                if (item && item.imgId) {
                    const file: UploadFile = {
                        uid: item.imgId.toString(),
                        name: item.imgName,
                        url: item.imgName
                    };
                    files.push(file);
                }
            });
            handleChange({ fileList: files });
        }
    }, [currentNetStation]);

    return (
        <NetInfoWrapper>
            {currentNetStation && !loading ? (
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    initialValues={currentNetStation}
                    style={{ width: '100%' }}
                    onFinishFailed={onFinishFailed}
                    onFinish={onFinish}>
                    <InfoContainer>
                        <Collapse
                            style={{ marginBottom: 10 }}
                            defaultActiveKey={['1']}>
                            <PanelCustom
                                header="Thông tin phòng máy"
                                key={1}
                                forceRender>
                                <Form.Item<INetStation>
                                    label="Tên phòng máy"
                                    name="netName"
                                    rules={[
                                        {
                                            min: 6,
                                            required: true,
                                            whitespace: true,
                                            message:
                                                'Tên phòng máy phải ít nhất 6 ký tự'
                                        }
                                    ]}>
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'netName',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>
                                <Form.Item<INetStation>
                                    label="Địa chỉ phòng máy"
                                    name="address"
                                    rules={[
                                        {
                                            min: 10,
                                            required: true,
                                            whitespace: true,
                                            message:
                                                'Địa chỉ phòng máy phải ít nhất 10 ký tự'
                                        }
                                    ]}>
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'address',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Tỉnh thành"
                                    name="cityId"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Chọn tỉnh thành!'
                                        }
                                    ]}>
                                    <Select
                                        placeholder="Chọn tỉnh thành"
                                        onChange={(e: any) => onCityChange(e)}>
                                        {listCity &&
                                            listCity.list.map((city) => {
                                                return (
                                                    <Option
                                                        key={city.cityId}
                                                        value={city.cityId}>
                                                        {city.cityName}
                                                    </Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Quận huyện"
                                    name="districtId"
                                    rules={[
                                        {
                                            required: true,

                                            message: 'Chọn quận huyện'
                                        }
                                    ]}>
                                    <Select
                                        placeholder="Chọn quận huyện"
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'districtId',
                                                e
                                            )
                                        }>
                                        {listDistrict &&
                                            listDistrict.list.map((item) => {
                                                return (
                                                    <Option
                                                        key={item.districtId}
                                                        value={item.districtId}>
                                                        {item.districtName}
                                                    </Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Item>

                                <Form.Item<INetStation>
                                    label="Số lượng máy trạm"
                                    name="pcs"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng nhập số lượng '
                                        },
                                        { validator: validateNumber }
                                    ]}>
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'pcs',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>
                            </PanelCustom>
                        </Collapse>

                        <Collapse style={{ marginBottom: 10 }}>
                            <PanelCustom
                                header="Thông tin chủ phòng máy"
                                key={1}
                                forceRender>
                                <Form.Item<INetStation>
                                    label="Tên chủ sở hữu"
                                    name="ownerName"
                                    rules={[
                                        {
                                            min: 6,
                                            required: true,
                                            whitespace: true,
                                            message:
                                                'Tên chủ sở hữu phải ít nhất 6 ký tự'
                                        }
                                    ]}>
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'ownerName',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>

                                <Form.Item<INetStation>
                                    label="Số điện thoại"
                                    name="ownerPhone"
                                    rules={[
                                        {
                                            min: 10,
                                            required: true,
                                            whitespace: true,
                                            message:
                                                'Số điện thoại phải ít nhất 10 ký tự'
                                        }
                                    ]}>
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'ownerPhone',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>

                                <Form.Item<INetStation>
                                    label="Email liên hệ"
                                    name="ownerEmail"
                                    rules={[
                                        {
                                            type: 'email',
                                            whitespace: true,
                                            message:
                                                'Email không đúng định dạng'
                                        }
                                    ]}>
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'ownerEmail',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>
                            </PanelCustom>
                        </Collapse>

                        <Collapse style={{ marginBottom: 10 }}>
                            <PanelCustom
                                header="Thông tin người quản lý"
                                key={3}
                                forceRender>
                                <Form.Item<INetStation>
                                    label="Tên người quản lý"
                                    name="managerName"
                                    rules={[
                                        {
                                            min: 6,
                                            whitespace: true,
                                            message:
                                                'Tên chủ sở hữu phải ít nhất 6 ký tự'
                                        }
                                    ]}>
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'managerName',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>

                                <Form.Item<INetStation>
                                    label="Số điện thoại"
                                    name="managerPhone"
                                    rules={[
                                        {
                                            min: 10,
                                            whitespace: true,
                                            message:
                                                'Số điện thoại phải ít nhất 10 ký tự'
                                        }
                                    ]}>
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'managerPhone',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>
                            </PanelCustom>
                        </Collapse>

                        <Collapse style={{ marginBottom: 10 }}>
                            <PanelCustom
                                header="Thông tin kỹ thuật viên"
                                key={4}
                                forceRender>
                                <Form.Item<INetStation>
                                    label="Tên kỹ thuật viên"
                                    name="techniqueName"
                                    rules={[
                                        {
                                            min: 6,
                                            whitespace: true,
                                            message:
                                                'Tên kỹ thuật viên phải ít nhất 6 ký tự'
                                        }
                                    ]}>
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'techniqueName',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>

                                <Form.Item<INetStation>
                                    label="Số điện thoại"
                                    name="techniquePhone"
                                    rules={[
                                        {
                                            min: 10,
                                            whitespace: true,
                                            message:
                                                'Số điện thoại phải ít nhất 10 ký tự'
                                        }
                                    ]}>
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'techniquePhone',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>
                            </PanelCustom>
                        </Collapse>

                        <Collapse style={{ marginBottom: 10 }}>
                            <PanelCustom
                                header="Thông tin xuất hóa đơn"
                                key={4}
                                forceRender>
                                <Form.Item<INetStation>
                                    label="Tên doanh nghiệp"
                                    name="companyName">
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'companyName',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>

                                <Form.Item<INetStation>
                                    label="Mã số thuế"
                                    name="companyTaxCode">
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'companyTaxCode',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>

                                <Form.Item<INetStation>
                                    label="Email"
                                    name="companyEmail"
                                    rules={[
                                        {
                                            type: 'email',
                                            message:
                                                'Email không đúng định dạng'
                                        }
                                    ]}>
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'companyEmail',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>

                                <Form.Item<INetStation>
                                    label="Địa chỉ"
                                    name="companyAddress">
                                    <Input
                                        onChange={(e: any) =>
                                            setPropertyValue(
                                                currentNetStation,
                                                'companyAddress',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Form.Item>

                                <UploadContainer>
                                    <p style={{ margin: 0 }}>
                                        Hình ảnh giấy phép kinh doanh
                                    </p>
                                    <PhotoDescription>
                                        (Upload tối đa 6 tấm hình)
                                    </PhotoDescription>
                                    <Upload
                                        accept=".png,.jpg"
                                        showUploadList={{
                                            showPreviewIcon: true
                                        }}
                                        beforeUpload={() => {
                                            return false;
                                        }}
                                        fileList={fileList}
                                        onChange={handleChange}
                                        onPreview={handlePreview}
                                        onRemove={handleRemove}
                                        listType="picture-card">
                                        {fileList.length >= 6
                                            ? null
                                            : uploadButton}
                                    </Upload>
                                    <List
                                        grid={{ gutter: 20, sm: 2, lg: 2 }}
                                        dataSource={fileList}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <img
                                                    src={item.preview}
                                                    alt={item.name}
                                                    style={{ width: '100%' }}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </UploadContainer>
                            </PanelCustom>
                        </Collapse>
                    </InfoContainer>

                    <ActionWrapper>
                        <Form.Item style={{ width: '100%' }}>
                            <Button
                                type="primary"
                                loading={loadingAction}
                                style={{ width: '100%', marginTop: '20px' }}
                                htmlType="submit">
                                Cập nhật thông tin
                            </Button>
                        </Form.Item>
                    </ActionWrapper>
                </Form>
            ) : null}
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    {' '}
                    <Spin />
                </div>
            ) : null}
            {openPreviewDialog ? (
                <DialogPreview
                    fileContent={fileContent}
                    visible={openPreviewDialog}
                    onClose={() => setOpenPreviewDialog(false)}
                />
            ) : null}
        </NetInfoWrapper>
    );
});
