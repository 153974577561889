import { ReactElement, FC } from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { Navigation } from './Navigation';

export const Header: FC = (): ReactElement => {
    const isDesktop = useMediaQuery('(min-width: 960px)');

    return (
        <>
            <Navigation />
        </>
    );
};
