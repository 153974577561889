import styled from 'styled-components';

export const NavigationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    //background-color: #148ade;
    border-bottom: solid 0px #148ade;
    //box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100%;
    z-index: 1000;
    padding: 5px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
`;

export const NavigationTitle = styled.p`
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: #148ade;
`;

export const NavigationHeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 70px;
    align-items: center;
`;
export const NavigationSubWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
`;
