import { IRequestBase } from './ITypeBase';

export enum APP_BUTTON_TYPE {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    BORDER = 'border',
    LIGHT = 'light',
    DISABLED = 'disabled',
    TRANSPARENT = 'transparent'
}

export interface ITabMenu {
    id: number;
    title: any;
}

export interface IGeoLocation {
    longitude: number;
    latitude: number;
    isGeolocationAvailable: boolean;
    isGeolocationEnabled: boolean;
}

export interface ICity {
    cityId: string | number;
    cityName: string;
}

export interface IDistrict {
    districtId: string | number;
    districtName: string;
}

export interface IGetListCityRequest extends IRequestBase {}

export interface IGetListDistrictRequest extends IRequestBase {
    data: {
        cityId: any;
        from?: number;
        size?: number;
    };
}

export interface ApiResponseList<T> {
    total: number;
    list: T;
}
