import styled from 'styled-components';

export const SurveyViewWrapper = styled.div`
    margin-top: 150px;
`;

export const SurveyContainer = styled.div`
    padding-bottom: 80px;
`;

export const ActionWrapper = styled.div`
    position: fixed;
    bottom: 0;
    padding: 5px;
    height: 70px;
    background-color: #f7f7f2;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: solid 1px #c7c6bf;
`;
