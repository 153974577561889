import { Modal } from 'antd';
import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles

interface Props {
    visible: boolean;
    onClose: () => void;
    fileContent: any;
}

export const DialogPreview: ComponentType<Props> = pure(
    ({ visible, onClose, fileContent }) => {
        return (
            <>
                <Modal
                    centered
                    title="Hình ảnh visit"
                    onCancel={onClose}
                    open={visible}
                    width={400}
                    footer={null}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={fileContent} style={{width: '60%'}} alt='' />
                    </div>
                </Modal>
            </>
        );
    }
);
