import { IGetListSurveyRequest, ISubmitSurveyRequest } from 'src/types/ISurvey';
import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import BaseAuthService from './BaseAuthService';
import {
    IGetListVisitResultRequest,
    IUpdateResultVisitRequest
} from 'src/types/IVisit';

class SurveyService extends BaseAuthService {
    private static instance: SurveyService;

    public static getInstance(): SurveyService {
        if (!SurveyService.instance) {
            SurveyService.instance = new SurveyService();
        }
        return SurveyService.instance;
    }

    getListSurvey = async (params: IGetListSurveyRequest) => {
        try {
            const response = await this.postData(
                API.NET_STATION.SURVEY,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getListVisitResult = async (params: IGetListVisitResultRequest) => {
        try {
            const response = await this.postData(
                API.NET_STATION.VISIT_STATUS,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    submitSurvey = async (params: ISubmitSurveyRequest) => {
        try {
            const response = await this.postData(
                API.NET_STATION.UPDATE_SURVEY,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    submitResultVisit = async (params: IUpdateResultVisitRequest) => {
        try {
            const response = await this.postData(
                API.NET_STATION.UPDATE_RESULT_VISIT,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}

export default SurveyService;
