import { ComponentType, useEffect } from 'react';
import { QuestionItemContainer, QuestionName } from './QuestionItem.styles';
import { IQuestion } from 'src/types/ISurvey';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { AnswerItem } from '../AnswerItem';

//components

//styles

interface Props {
    data: IQuestion;
}

export const QuestionItem: ComponentType<Props> = observer(({ data }) => {
    useEffect(() => {}, []);

    return (
        <QuestionItemContainer>
            <QuestionName>{data.questName}</QuestionName>
            {data.answerDetails.map((answer) => {
                return (
                    <AnswerItem
                        data={answer}
                        key={answer.answerId}
                        question={data}
                    />
                );
            })}
        </QuestionItemContainer>
    );
});
