import { useRootStore } from "src/providers/RootStoreProvider";
import { QuestionItem } from "./QuestionItem";
import { observer } from "mobx-react-lite";

const SaleInfo = observer(({}) => {
    const store = useRootStore();
    const { historyVisitResult } = store.historyStore;
    return <div>
        {historyVisitResult ? <QuestionItem data={historyVisitResult} /> : null}
    </div>
});
export default SaleInfo;