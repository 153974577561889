import { Card, Collapse } from 'antd';
import styled from 'styled-components';
const { Panel } = Collapse;

export const NetInfoWrapper = styled.div`
    display: flex;
    margin-top: 150px;
`;

export const InfoContainer = styled.div`
    padding-bottom: 70px;
    margin: 10px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ccecfc;
    border: solid 1px #7cd2fc;
    height: 40px;
    margin-top: 30px;
    margin-bottom: 10px;
    border-radius: 10px;
`;

export const InfoTitle = styled.p`
    font-size: 14px;
    font-weight: 600;
    color: #000;
    padding: 8px;
    margin-top: 10px;
    display: flex;
`;

export const ActionWrapper = styled.div`
    position: fixed;
    bottom: 0;
    padding: 5px;
    height: 70px;
    background-color: #f7f7f2;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: solid 1px #c7c6bf;
`;

export const PanelCustom = styled(Panel)`
    .ant-collapse-header-text {
        font-weight: 600;
    }
`;

export const UploadContainer = styled.div`
    margin: 10px;
`;

export const PhotoDescription = styled.p`
    font-size: 12px;
    font-weight: 400;

    color: #7f8280;
`;
