import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';
import { QuestionItemContainer, QuestionName } from './QuestionItem.styles';
import { AnswerType, IQuestion } from 'src/types/ISurvey';
import { observer } from 'mobx-react-lite';
import { Checkbox } from 'antd';
import _ from 'lodash';
import { AnswerItem } from '../AnswerItem';
import { IResultVisit } from 'src/types/IVisit';
//components

//styles

interface Props {
    data: Array<IResultVisit>;
}

export const QuestionItem: ComponentType<Props> = observer(({ data }) => {
    useEffect(() => {}, []);

    return (
        <QuestionItemContainer>
            <QuestionName>Kết quả sale phòng máy</QuestionName>
            {data.map((answer) => {
                return <AnswerItem data={answer} key={answer.statusId} />;
            })}
        </QuestionItemContainer>
    );
});
