import GlobalStore from './GlobalStore';
import AuthStore from './AuthStore';
import JSonStore from './JSonStore';
import SurveyStore from './SurveyStore';
import NetStationStore from './NetStationStore';
import VisitStore from './VisitStore';
import HistoryStore from './HistoryStore';
class RootStore {
    globalStore: GlobalStore;
    authStore: AuthStore;
    jsonStore: JSonStore;
    surveyStore: SurveyStore;
    netStationStore: NetStationStore;
    visitStore: VisitStore;
    historyStore: HistoryStore;

    constructor() {
        this.globalStore = new GlobalStore(this);
        this.authStore = new AuthStore(this);
        this.jsonStore = new JSonStore(this);
        this.surveyStore = new SurveyStore(this);
        this.netStationStore = new NetStationStore(this);
        this.visitStore = new VisitStore(this);
        this.historyStore = new HistoryStore(this);
    }
}
export default RootStore;
