import { observer } from 'mobx-react-lite';
import { ComponentType, useEffect } from 'react';
import { useRootStore } from 'src/providers/RootStoreProvider';
import {
    IGetHistoryPhoto,
    IGetHistorySaleResult,
    IGetHistorySurvey
} from 'src/types/IVisit';
import Header from './Header';
import { NavigationHistoryEnum } from 'src/types/INavigation';
import NetInfo from './NetInfo';
import PhotoInfo from './PhotoInfo';
import SurveyInfo from './SurveyInfo';
import SaleInfo from './SaleInfo';

interface Props {}

const HistoryDetail: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const {
        currentHistory,
        getHistoryPhoto,
        getHistorySurvey,
        getHistoryVisitResult
    } = store.historyStore;
    const { indexHistoryMenu } = store.globalStore;

    const onGetHistoryPhoto = () => {
        const params: IGetHistoryPhoto = {
            data: {
                netId: currentHistory?.netId!,
                visitId: currentHistory?.visitId!
            }
        };
        getHistoryPhoto(params);
    };

    const onGetHistorySurvey = () => {
        const params: IGetHistorySurvey = {
            data: {
                netId: currentHistory?.netId!,
                visitId: currentHistory?.visitId!
            }
        };
        getHistorySurvey(params);
    };

    const onGetHistorySaleResult = () => {
        const params: IGetHistorySaleResult = {
            data: {
                netId: currentHistory?.netId!,
                visitId: currentHistory?.visitId!
            }
        };
        getHistoryVisitResult(params);
    };

    useEffect(() => {
        if (!currentHistory) return;
        onGetHistoryPhoto();
        onGetHistorySurvey();
        onGetHistorySaleResult();
    }, [currentHistory]);

    const renderContent = () => {
        switch (indexHistoryMenu) {
            case NavigationHistoryEnum.NetInfo:
                return renderInfoHistory();
            case NavigationHistoryEnum.PhotoInfo:
                return <PhotoInfo />
            case NavigationHistoryEnum.SurveyInfo:
                return <SurveyInfo />
            case NavigationHistoryEnum.SaleInfo:
                return <SaleInfo />
        }
    }

    const renderInfoHistory = () => {
        return <NetInfo />
    }

    const renderSurveyHistory = () => {
        return <div>render survey his</div>
    }

    const renderSaleHistory = () => {
        return <div>render sale his</div>
    }

    return (
        <div style={{ marginTop: 150, display: 'flex', flexDirection:'column' }}>
            <div>
                <Header />
            </div>
            <div>
                {renderContent()}
            </div>
        </div>
    );
});

export default HistoryDetail;
