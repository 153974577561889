import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';
import {
    AnswerType,
    IAnswer,
    IQuestion,
    QuestionChoiceType
} from 'src/types/ISurvey';
import {
    AnswerCheck,
    AnswerItemContainer,
    AnswerItemInput,
    AnswerName
} from './AnswerItem.styles';
import { useRootStore } from 'src/providers/RootStoreProvider';
import { runInAction } from 'mobx';
import { Input } from 'antd';
import { IResultVisit } from 'src/types/IVisit';
import TextArea from 'antd/lib/input/TextArea';

//components

//styles

interface Props {
    data: IResultVisit;
}

export const AnswerItem: ComponentType<Props> = observer(({ data }) => {
    const store = useRootStore();
   

    const renderAnswer = () => {
        if (Number(data.answerType) === AnswerType.Selection)
            return (
                <AnswerItemContainer>
                    <AnswerCheck
                        isChecked={data.selected ? 'checked' : 'none'}
                    />
                    <AnswerName>{data.status}</AnswerName>
                </AnswerItemContainer>
            );
        else
            return (
                <AnswerItemInput>
                    <AnswerName>{data.status}</AnswerName>
                    <TextArea rows={6} showCount={true} placeholder="Ghi chú khác..." maxLength={500}
                        defaultValue={data.extInput}
                        disabled={true}
                    />
                </AnswerItemInput>
            );
    };

    return <>{renderAnswer()}</>;
});
