import { ICity, IDistrict } from 'src/types/IGlobal';
import { DataList } from './DataList';

export default class City {
    cityId: string | number;
    cityName: string;

    constructor(cityId: string | number, cityName: string) {
        this.cityId = cityId;
        this.cityName = cityName;
    }

    static fromJson = (json: ICity): City => {
        return new City(json.cityId, json.cityName);
    };
}

export class ListCity extends DataList<City> {
    static fromJson = (jsonArray: Array<ICity>): Array<City> => {
        if (!jsonArray) return [];
        let data = new Array<City>();
        jsonArray.forEach((item) => {
            data.push(City.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}

export class District {
    districtId: string | number;
    districtName: string;

    constructor(districtId: string | number, districtName: string) {
        this.districtId = districtId;
        this.districtName = districtName;
    }

    static fromJson = (json: IDistrict): District => {
        return new District(json.districtId, json.districtName);
    };
}

export class ListDistrict extends DataList<District> {
    static fromJson = (jsonArray: Array<IDistrict>): Array<District> => {
        if (!jsonArray) return [];
        let data = new Array<District>();
        jsonArray.forEach((item) => {
            data.push(District.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
