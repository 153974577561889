import { observer } from 'mobx-react-lite';
import { ComponentType } from 'react';
import {
    AnswerType,
    IAnswer,
    IQuestion,
    QuestionChoiceType
} from 'src/types/ISurvey';
import {
    AnswerCheck,
    AnswerItemContainer,
    AnswerItemInput,
    AnswerName
} from './AnswerItem.styles';
import { useRootStore } from 'src/providers/RootStoreProvider';
import { runInAction } from 'mobx';
import { Input } from 'antd';

//components

//styles

interface Props {
    data: IAnswer;
    question: IQuestion;
}

export const AnswerItem: ComponentType<Props> = observer(
    ({ data, question }) => {
        const store = useRootStore();
        const { unCheckAllAnswer } = store.surveyStore;

        const onChecked = () => {
            if (Number(question.questType) === QuestionChoiceType.Option)
                unCheckAllAnswer(1, question);
            runInAction(() => {
                data.selected = !data.selected;
            });
        };

        const onInputExtData = (e: any) => {
            runInAction(() => {
                data.extInput = e.target.value;
            });
        };

        const renderAnswer = () => {
            if (Number(data.answerType) === AnswerType.Selection)
                return (
                    <AnswerItemContainer onClick={onChecked}>
                        <AnswerCheck
                            isChecked={data.selected ? 'checked' : 'none'}
                        />
                        <AnswerName>{data.answerName}</AnswerName>
                    </AnswerItemContainer>
                );
            else
                return (
                    <AnswerItemInput>
                        <AnswerName>{data.answerName}</AnswerName>
                        <Input
                            defaultValue={data.extInput}
                            onChange={onInputExtData}
                        />
                    </AnswerItemInput>
                );
        };

        return <>{renderAnswer()}</>;
    }
);
