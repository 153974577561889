import { ComponentType, useEffect, useState } from 'react';
import {
    ActionWrapper,
    InfoContainer,
    PhotoDescription,
    UploadContainer,
    VisitInfoWrapper
} from './index.styles';
import { Button, Form, Input, List, message, Modal, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile, UploadFile } from 'antd/lib/upload';
import Upload from 'antd/lib/upload/Upload';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'src/providers/RootStoreProvider';
import { IGetVisitInfoRequest, ILocation } from 'src/types/IVisit';
import { useForm } from 'antd/lib/form/Form';
import { showMessage } from 'src/utils';
import { NavigationEnum } from 'src/types/INavigation';
import { DialogPreview } from './DialogPreview';

//components

//styles

interface Props {}

export const VisitInfo: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { currentNetStation } = store.netStationStore;
    const { visitData, getVisitInfo, updateVisitInfo } = store.visitStore;
    const { geoLocation, setAppTitle, changeMainMenuIndex } = store.globalStore;
    const [form] = useForm();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
    const [fileContent, setFileContent] = useState('');
    const [loadingAction, setLoadingAction] = useState<boolean>(false);

    const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
        // Ẩn tên của các tệp
        const updatedFileList = fileList.map((file) => ({
            ...file,
            name: ''
        }));
        setFileList(updatedFileList);
    };

    const handlePreview = async (file: UploadFile) => {
        console.log(file);
        if (!file.url && !file.preview && file.originFileObj) {
            const fileContent = await getBase64(file.originFileObj);
            setFileContent(fileContent);
        }
        else setFileContent(file.url!);
        setOpenPreviewDialog(true);
    };

    const handleRemove = (file: UploadFile) => {
        console.log('Removing file', file);
        setFileList((prevFileList) =>
            prevFileList.filter((item) => item !== file)
        );
    };

    const handleUpload = () => {
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('files', file.originFileObj!);
        });
        console.log(fileList);
        console.log(formData.get('files'));
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        return isJpgOrPng;
    };

    const getBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    };

    const uploadButton = (
        <div>
            <UploadOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onFinish = async (values: any) => {
        try {
            console.log('value:', values);
            console.log('fileList', fileList);
            setLoadingAction(true);
            const formData = new FormData();
            const location: ILocation = {
                longitude: geoLocation?.longitude!,
                latitude: geoLocation?.latitude!
            };

            fileList.forEach((file) => {
                formData.append('files', file.originFileObj!);
            });
            formData.append('netId', currentNetStation?.netId.toString()!);
            formData.append('location', JSON.stringify(location));
            formData.append('longitude', location.longitude.toString());
            formData.append('latitude', location.latitude.toString());
            formData.append('note', values.note);

            console.log('formData', formData);
            const result = await updateVisitInfo(formData);
            setLoadingAction(false);
            showMessage(result);
        } catch (error: any) {
            Modal.error({
                title: 'Lỗi xảy ra',
                content: error.message,
                centered: true
            });
        } finally {
            setLoadingAction(false);
        }
    };

    const onGetVisitInfo = async () => {
        try {
            setLoading(true);
            const params: IGetVisitInfoRequest = {
                data: {
                    netId: currentNetStation?.netId!
                }
            };
            await getVisitInfo(params);
            setLoading(false);
        } catch (error: any) {
            Modal.error({
                title: 'Lỗi xảy ra',
                content: error.message,
                centered: true
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        onGetVisitInfo();
        setAppTitle(currentNetStation?.netName!);
        changeMainMenuIndex(NavigationEnum.Visit);
    }, []);

    useEffect(() => {
        if (visitData) handleChange({ fileList: visitData.fileList });
        else setFileList([]);
    }, [visitData]);

    useEffect(() => {
        if (!visitData)
            form.setFieldsValue({
                longitude: geoLocation?.longitude,
                latitude: geoLocation?.latitude,
                note: ''
            });
        else {
            form.setFieldsValue({
                // longitude: visitData.location.longitude,
                // latitude: visitData.location.latitude,
                longitude: geoLocation?.longitude,
                latitude: geoLocation?.latitude,
                note: visitData.note
            });
        }
    }, [geoLocation, form, visitData]);

    return (
        <VisitInfoWrapper>
            {loading ? (
                <Spin />
            ) : (
                <>
                    <UploadContainer>
                        <p style={{ margin: 0 }}>Hình ảnh phòng máy</p>
                        <PhotoDescription>
                            (Upload tối đa 6 tấm hình về phòng máy)
                        </PhotoDescription>
                        <Upload
                            accept=".png,.jpg"
                            showUploadList={{ showPreviewIcon: true }}
                            beforeUpload={() => {
                                return false;
                            }}
                            fileList={fileList}
                            onChange={handleChange}
                            onPreview={handlePreview}
                            onRemove={handleRemove}
                            listType="picture-card">
                            {fileList.length >= 6 ? null : uploadButton}
                        </Upload>
                        <List
                            grid={{ gutter: 20, sm: 2, lg: 2 }}
                            dataSource={fileList}
                            renderItem={(item) => (
                                <List.Item>
                                    <img
                                        src={item.preview}
                                        alt={item.name}
                                        style={{ width: '100%' }}
                                    />
                                </List.Item>
                            )}
                        />
                    </UploadContainer>

                    {geoLocation ? (
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            form={form}
                            layout="vertical"
                            style={{ width: '100%' }}>
                            <InfoContainer>
                                {/* <Form.Item
                            label="Tọa độ (longitude)"
                            name="longitude"
                            rules={[
                                {
                                    required: true,
                                    message: 'Tọa độ (longitude) không hợp lệ'
                                }
                            ]}>
                            <Input disabled={true} />
                        </Form.Item>

                        <Form.Item
                            label="Tọa độ (latitude)"
                            name="latitude"
                            rules={[
                                {
                                    required: true,
                                    message: 'Tọa độ (latitude) không hợp lệ'
                                }
                            ]}>
                            <Input disabled={true} />
                        </Form.Item> */}

                                <Form.Item label="Ghi chú" name="note">
                                    <Input.TextArea
                                        rows={6}
                                        showCount={true}
                                        maxLength={500}
                                    />
                                </Form.Item>
                            </InfoContainer>

                            <ActionWrapper>
                                <Form.Item style={{ width: '100%' }}>
                                    <Button
                                        type="primary"
                                        loading={loadingAction}
                                        style={{
                                            width: '100%',
                                            marginTop: '20px'
                                        }}
                                        htmlType="submit"
                                        disabled={fileList.length === 0}>
                                        Cập nhật thông tin
                                    </Button>
                                </Form.Item>
                            </ActionWrapper>
                        </Form>
                    ) : null}
                </>
            )}
            {openPreviewDialog ? (
                <DialogPreview
                    fileContent={fileContent}
                    visible={openPreviewDialog}
                    onClose={() => setOpenPreviewDialog(false)}
                />
            ) : null}
        </VisitInfoWrapper>
    );
});
