import { useRootStore } from 'src/providers/RootStoreProvider';
import { QuestionItem } from './QuestionItem';
import { observer } from 'mobx-react-lite';

const SurveyInfo = observer(({}) => {
    const store = useRootStore();
    const { historySurvey } = store.historyStore;
    return (
        <div>
            {historySurvey &&
                historySurvey[0].questList.map((question) => {
                    return (
                        <QuestionItem data={question} key={question.questId} />
                    );
                })}
        </div>
    );
});
export default SurveyInfo;
