import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import RootStore from './RootStore';
import {
    IGetListSurveyRequest,
    IQuestion,
    ISubmitSurveyRequest,
    ISurvey
} from 'src/types/ISurvey';
import data from 'src/data/survey.json';
import _ from 'lodash';
import { isAnswerValid } from 'src/utils';
import SurveyService from 'src/services/SurveyService';
import { APIResponse, APIResponseList } from 'src/models/APIResponse';
import { IResponseBase } from 'src/types/ITypeBase';
import {
    IGetListVisitResultRequest,
    IResultVisit,
    IUpdateResultVisitRequest
} from 'src/types/IVisit';

class SurveyStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable listSurvey: Array<ISurvey> | null = null;
    @observable listVisitResult: Array<IResultVisit> | null = null;
    @observable currentSurvey: ISurvey | null = null;
    @observable loading: boolean = false;

    @action resetListSurvey = () => {
        runInAction(() => {
            this.listSurvey = null;
        });
    };

    @action getListSurvey = async (params: IGetListSurveyRequest) => {
        this.loading = true;
        this.resetListSurvey();
        const result = (await SurveyService.getInstance().getListSurvey(
            params
        )) as APIResponseList<Array<ISurvey>>;

        if (result && result.isSuccess())
            runInAction(() => {
                this.listSurvey = result.data?.list!;
            });
        console.log('List survey', toJS(this.listSurvey));
        this.loading = false;
        return result;
    };

    @action getListVisitResult = async (params: IGetListVisitResultRequest) => {
        this.loading = true;
        const result = (await SurveyService.getInstance().getListVisitResult(
            params
        )) as APIResponseList<Array<IResultVisit>>;

        if (result && result.isSuccess())
            runInAction(() => {
                this.listVisitResult = result.data?.list!;
            });
        console.log('List listVisitResult', toJS(this.listVisitResult));
        this.loading = false;
        return result;
    };

    @action unCheckAllAnswer = async (
        surveyId: number,
        question: IQuestion
    ) => {
        runInAction(() => {
            const surveyData = _.find(
                this.listSurvey,
                (item) => item.surveyId === surveyId
            );

            const data: IQuestion | undefined = _.find(
                surveyData?.questList,
                (item: IQuestion) => {
                    return item.questId === question.questId;
                }
            );
            if (data) {
                data.answerDetails.map((answer) => (answer.selected = false));
            }
        });
    };

    @action unCheckAllAnswerResultSale = async () => {
        runInAction(() => {
            this.listVisitResult?.map((item) => (item.selected = false));
        });
    };

    @action isDoneSurvey = (): boolean => {
        let done: boolean = true;
        _.some(this.listSurvey, (survey) => {
            _.some(survey.questList, (quest) => {
                if (!isAnswerValid(toJS(quest))) {
                    done = false;
                    return true;
                }
            });
        });
        console.log(done);
        return done;
    };

    @action isDoneVisitResult = (): boolean => {
        let done: boolean = false;
        _.some(this.listVisitResult, (data: IResultVisit) => {
            if (data.selected || data.extInput) {
                done = true;
                return true;
            }
        });
        console.log(done);
        return done;
    };

    @action submitSurvey = async (params: ISubmitSurveyRequest) => {
        const result = (await SurveyService.getInstance().submitSurvey(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };

    @action submitResultVisit = async (params: IUpdateResultVisitRequest) => {
        const result = (await SurveyService.getInstance().submitResultVisit(
            params
        )) as APIResponse<IResponseBase>;
        return result;
    };
}
export default SurveyStore;
