export enum NavigationEnum {
    Home,
    Info,
    Visit,
    Survey,
    Result,
    History
}
export enum NavigationHistoryEnum {
    NetInfo,
    PhotoInfo,
    SurveyInfo,
    SaleInfo
}
export interface INavigation {
    id: number;
    title: string;
    icon: any;
    link?: any;
}
