import styled from 'styled-components';

export const QuestionItemContainer = styled.div`
    margin: 10px;
    width: 100%;
`;

export const QuestionItemWrapper = styled.div``;

export const QuestionName = styled.p`
    font-size: 14px;
    background-color: #ccecfc;
    border: solid 1px #7cd2fc;
    font-weight: 600;
    color: #000;
    padding: 8px;
    margin-top: 30px;
    display: flex;
    border-radius: 10px;
`;
