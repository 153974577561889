import { ComponentType } from 'react';
import pure from 'recompose/pure';
import { ErrorDescription, ErrorTitle, LocationWrapper } from './index.styles';

//components

//styles

interface Props {}

export const LocationDenied: ComponentType<Props> = pure(({}) => {
    return (
        <LocationWrapper>
            <ErrorTitle>Access Denied</ErrorTitle>
            <ErrorDescription>
                Thiết bị của bạn không hỗ trợ hoặc không cấp quyền cho phép truy
                cập vào vị trí. Vui lòng kiểm tra và thử lại.
            </ErrorDescription>
        </LocationWrapper>
    );
});
