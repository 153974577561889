import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles

interface Props {}

export const TicketView: ComponentType<Props> = pure(({}) => {
    return <></>;
});
