import { IRequestBase } from './ITypeBase';

export enum QuestionChoiceType {
    Select,
    Option
}

export enum AnswerType {
    Selection,
    InputText
}

export interface ISurvey {
    surveyId: number;
    surveyName: string;
    questList: Array<IQuestion>;
}

export interface IAnswer {
    answerId: number;
    answerName: string;
    selected?: boolean | number;
    extInput?: string;
    answerType: AnswerType;
}

export interface IQuestion {
    questId: number;
    questName: string;
    questType: QuestionChoiceType;
    answerDetails: Array<IAnswer>;
}

export interface IGetListSurveyRequest extends IRequestBase {
    data: {
        netId: string | number;
    };
}
export interface ISubmitSurveyRequest extends IRequestBase {
    data: {
        netId: string | number;
        surveyId: string | number;
        questList: Array<IQuestion>;
    };
}
