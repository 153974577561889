import { ComponentType } from 'react';
import {
    NavigationHeaderWrapper,
    NavigationSubWrapper,
    NavigationTitle,
    NavigationWrapper,
    TitleWrapper
} from './Navigation.styles';
import { INavigation, NavigationHistoryEnum } from 'src/types/INavigation';

import { LuListChecks } from 'react-icons/lu';
import { FaCarAlt, FaRegCalendarCheck } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'src/providers/RootStoreProvider';
import { FaInfoCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { IoMdLogOut } from 'react-icons/io';
import { NavigationItem } from '../NavigationItem';

//components

//styles

interface Props {}

export const Navigation: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { logout } = store.authStore;
    const navigate = useNavigate();
    const { indexHistoryMenu, appTitle } = store.globalStore;

    const navigation: Array<INavigation> = [
        // {
        //     id: NavigationHistoryEnum.NetInfo,
        //     title: 'Thông tin',
        //     link: '/info',
        //     icon: <FaInfoCircle size={30} />
        // },
        {
            id: NavigationHistoryEnum.PhotoInfo,
            title: 'Visit',
            link: '/info',
            icon: <FaCarAlt size={30} />
        },
        {
            id: NavigationHistoryEnum.SurveyInfo,
            title: 'Khảo sát',
            link: '/visit',
            icon: <LuListChecks size={30} />
        },
        {
            id: NavigationHistoryEnum.SaleInfo,
            title: 'Kết quả',
            link: '/survey',
            icon: <FaRegCalendarCheck size={30} />
        },
    ];

    return (
        <NavigationWrapper>
            <NavigationSubWrapper>
                {navigation.map((item) => {
                    return <NavigationItem data={item} key={item.id} />;
                })}
            </NavigationSubWrapper> 
        </NavigationWrapper>
    );
});
