import { ComponentType, useEffect, useState } from 'react';
import { NetListWrapper, SearchButton, SearchWrapper } from './index.styles';
import { NetItem } from './NetItem';
import { Button, DatePicker, Form, Input, Modal, Spin, TimePicker } from 'antd';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'src/providers/RootStoreProvider';
import { Fab } from 'react-tiny-fab';
import {
    IGetListNetStationRequest,
    INetStation,
    INetStationFilter
} from 'src/types/INetStation';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { NoData } from '../CommonView/NoData/NoData';
import { NavigationEnum } from 'src/types/INavigation';
import { useForm } from 'react-hook-form';
import { AppButton } from 'src/styles/AppButton.styles';
import { APP_BUTTON_TYPE } from 'src/types/IGlobal';
//components

//styles

interface Props {}

export const NetList: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const {
        loading,
        listNetStation,
        netStationFilter,
        setNetStationData,
        getListNetStation,
        updateNetStationFilter
    } = store.netStationStore;
    const { resetListSurvey } = store.surveyStore;
    const { resetVisitData } = store.visitStore;
    const { setAppTitle, changeMainMenuIndex } = store.globalStore;

    const [visible, setVisible] = useState(false);
    const [reload, setReload] = useState<boolean>(false);
    const [description, setDescription] = useState('');
    const navigate = useNavigate();

    const handleOk = () => {
        setVisible(false);
        setReload(!reload);
        resetListSurvey();
        resetVisitData();
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onGetListNetStation = async () => {
        try {
            const params: IGetListNetStationRequest = {
                data: {
                    netName: netStationFilter.netName,
                    startDate: netStationFilter.startDate.format('DDMMYYYY'),
                    endDate: netStationFilter.endDate.format('DDMMYYYY')
                }
            };
            const result = await getListNetStation(params);
            setDescription(result.getDescription());
        } catch (error: any) {
            Modal.error({
                title: 'Lỗi xảy ra',
                content: error.message,
                centered: true
            });
        }
    };

    useEffect(() => {
        setAppTitle('Danh sách phòng máy');
        changeMainMenuIndex(NavigationEnum.Home);
    }, []);

    useEffect(() => {
        onGetListNetStation();
    },[netStationFilter]);

    const onAddNetStation = () => {
        const data: INetStation = {
            netId: 0,
            cityId: 439
        };
        setNetStationData(data);
        navigate('/info');
    };

    const renderNoData = () => {
        if (!listNetStation?.hasData() && !listNetStation?.isLoading)
            return <NoData description={description} />;
        else return null;
    };

    const onFinish = (values: INetStationFilter) => {
        updateNetStationFilter(values);
        console.log('Received values:', values);
    };

    return (
        <NetListWrapper>
            {/* <NetListTitle>Danh sách phòng máy</NetListTitle> */}
            <SearchWrapper>
                <Form
                    name="myForm"
                    initialValues={netStationFilter}
                    onFinish={onFinish}>
                    <div style={{ display: 'flex', gap: 10 }}>
                        <Form.Item
                            name="startDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select start date!'
                                }
                            ]}>
                            <DatePicker allowClear={false} />
                        </Form.Item>

                        <Form.Item
                            name="endDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select end date!'
                                }
                            ]}>
                            <DatePicker allowClear={false} />
                        </Form.Item>

                        
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Form.Item name="netName">
                            <Input placeholder="Tên phòng máy..." />
                        </Form.Item>

                        <Form.Item>
                            <SearchButton type="primary" typeButton={APP_BUTTON_TYPE.PRIMARY} htmlType="submit" >
                                Tìm kiếm
                            </SearchButton>
                        </Form.Item>
                    </div>
                </Form>
            </SearchWrapper>

            {listNetStation?.isLoading || loading ? <Spin /> : null}
            {listNetStation &&
                listNetStation.list.map((item) => {
                    return <NetItem key={item.netId} data={item} />;
                })}
            {renderNoData()}
            <Modal
                title="Xác nhận chuyển phòng máy"
                open={visible}
                onOk={handleOk}
                okText="Xác nhận"
                cancelText="Bỏ qua"
                onCancel={handleCancel}>
                <p>
                    Tất cả dữ liệu bạn chưa lưu sẽ bị mất. Bạn có chắc chắn muốn
                    chuyển phòng máy?
                </p>
            </Modal>

            <Fab
                alwaysShowTitle={false}
                text="Thêm phòng máy"
                onClick={() => onAddNetStation()}
                mainButtonStyles={{
                    backgroundColor: '#eb4034',
                    cursor: 'pointer'
                }}
                icon={<PlusOutlined />}></Fab>
        </NetListWrapper>
    );
});
