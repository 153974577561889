import { AxiosResponse } from 'axios';
import APIHelper from '../helpers/APIHelper';
import { IRequestBase, IResponseBase } from '../types/ITypeBase';
import { AuthHeader } from 'src/helpers/AuthHeader';
import { HTTP_STATUS } from 'src/configs/HttpStatus';
import { API_STATUS } from 'src/configs/APIStatus';

class BaseUploadService {
    public constructor() {
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            Authorization: 'Bearer ' + AuthHeader()?.accessToken
        };
        APIHelper.defaults.headers.common = headers;
        APIHelper.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    }
    postData(api: string, params: FormData) {
        return APIHelper.post(api, params);
    }
    getData(api: string, params: IRequestBase) {
        return APIHelper.post(api, params);
    }

    protected handleResponse = (response: AxiosResponse): IResponseBase => {
        if (response.status != HTTP_STATUS.OK) return null!;

        const result: IResponseBase = {
            data: response.data.data,
            err: response.data.err,
            errTitle: response.data.errTitle,
            errMsg: response.data.errMsg,

            responseCode: function (): number {
                return this.err!;
            },
            isSuccess: function (): boolean {
                return this.err === API_STATUS.OK;
            },
            isError: function (): boolean {
                return this.err != API_STATUS.OK;
            },
            getDescription: function (): string {
                return this.errMsg!;
            },
            getTitle: function (): string {
                return this.errTitle!;
            }
        };

        return result;
    };
}

export default BaseUploadService;
