import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { useGeolocated } from 'react-geolocated';

import AppRouters from './configs/AppRouters';
import { useRootStore } from './providers/RootStoreProvider';
import { LOCAL_KEY } from './configs/AppKey';

//css import
import 'antd/dist/antd.min.css';
import './styles/AntdDesign.css';
import GlobalStyle from './styles/GlobalStyle';
import { IGeoLocation, IGetListCityRequest } from './types/IGlobal';

const App: React.FC = () => {
    const store = useRootStore();
    const { changeLanguage, setGeoLocationData } = store.globalStore;
    const { listCity, getListCity } = store.netStationStore;

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false
            },
            userDecisionTimeout: 5000
        });

    useEffect(() => {
        onInitAppLanguage();
        if (!listCity || listCity.list.length === 0) onGetListCity();
    }, []);

    const onGetListCity = () => {
        const params: IGetListCityRequest = {};
        getListCity(params);
    };

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            // Cancel the event (modern browsers will use a generic message)
            event.preventDefault();
            // Chrome requires returnValue to be set
            event.returnValue = '';
            // Display a custom confirmation message
            const confirmationMessage =
                'Are you sure you want to leave? Your changes may not be saved.';
            event.returnValue = confirmationMessage; // For legacy browsers
            console.log('User is leaving the page'); // Log a message when the user chooses to leave
            return confirmationMessage; // For modern browsers
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        const geoLocation: IGeoLocation = {
            longitude: coords ? coords.longitude : 0,
            latitude: coords ? coords.latitude : 0,
            isGeolocationAvailable: isGeolocationAvailable,
            isGeolocationEnabled: isGeolocationEnabled
        };
        setGeoLocationData(geoLocation);
    }, [
        coords,
        isGeolocationAvailable,
        isGeolocationEnabled,
        setGeoLocationData
    ]);

    //disable console logging when build production
    if (process.env.NODE_ENV === 'production') {
        console.log = () => {};
        console.error = () => {};
        console.debug = () => {};
    }

    //init default app language
    const onInitAppLanguage = () => {
        const key = localStorage.getItem(LOCAL_KEY.USER_LANGUAGE);
        if (key && key == 'en') changeLanguage(key);
        else changeLanguage('vi');
    };

    return (
        <BrowserRouter basename={process.env.REACT_APP_HOMEPAGE}>
            <GlobalStyle />
            <AppRouters />
        </BrowserRouter>
    );
};

export default App;
