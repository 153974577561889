import { ComponentType, useState } from 'react';
import {
    ActionButton,
    ActionWrapper,
    CompleteWrapper,
    InfoWrapper,
    NetAddress,
    NetItemWrapper,
    NetName,
    NetPhone
} from './index.styles';
import { FaCircleCheck, FaLocationDot, FaRectangleList } from 'react-icons/fa6';
import { FaPhone } from 'react-icons/fa6';
import { NetStation } from 'src/models/NetStation';
import { observer } from 'mobx-react-lite';
import { Button, message } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
import { APP_BUTTON_TYPE } from 'src/types/IGlobal';
import { useNavigate } from 'react-router-dom';
import { useRootStore } from 'src/providers/RootStoreProvider';
//components

//styles

interface Props {
    data?: NetStation;
    onClick?: (data: NetStation) => void;
}

export const NetItem: ComponentType<Props> = observer(({ data, onClick }) => {
    const navigate = useNavigate();
    const store = useRootStore();
    const [visitable, setVisiable] = useState(false);
    const { setNetStationData } = store.netStationStore;

    const goToScreen = (link: string) => {
        setNetStationData(data);
        navigate(link);
    };

    console.log(data);

    return (
        <NetItemWrapper onClick={() => {}}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 5
                }}
                onClick={() => goToScreen('/history')}>
                <NetName style={{ margin: 0 }}>
                    {data?.netId} - {data?.netName}
                </NetName>
                <FaRectangleList size={22} style={{ marginLeft: 10 }} />
            </div>
            <InfoWrapper>
                <FaLocationDot size={18} color="red" />
                <NetAddress>
                    {data?.address} {data?.cityName} {data?.districtName}
                </NetAddress>
            </InfoWrapper>
            <InfoWrapper>
                <FaPhone size={16} color="#08bd98" />
                <NetPhone>{data?.ownerPhone}</NetPhone>
            </InfoWrapper>

            <ActionWrapper>
                {data?.isFlagLock === 0 ? (
                    <ActionButton
                        typeButton={APP_BUTTON_TYPE.PRIMARY}
                        onClick={() => {
                            goToScreen('/info');
                        }}>
                        Thông tin
                    </ActionButton>
                ) : (
                    <ActionButton typeButton={APP_BUTTON_TYPE.DISABLED}>
                        Thông tin
                    </ActionButton>
                )}

                {data?.numberVisit! >= 0 && data?.isFlagLock === 0 ? (
                    <ActionButton
                        typeButton={APP_BUTTON_TYPE.PRIMARY}
                        onClick={() => {
                            goToScreen('/visit');
                        }}>
                        Visit{' '}
                        {data?.numberVisit! > 0 ? `(${data?.numberVisit})` : ''}
                    </ActionButton>
                ) : (
                    <ActionButton typeButton={APP_BUTTON_TYPE.DISABLED}>
                        Visit
                    </ActionButton>
                )}

                {data?.numberSurvey! >= 0 && data?.isFlagLock === 0 ? (
                    <ActionButton
                        typeButton={APP_BUTTON_TYPE.PRIMARY}
                        onClick={() => {
                            goToScreen('/survey');
                        }}>
                        Khảo sát{' '}
                        {data?.numberSurvey! > 0
                            ? `(${data?.numberSurvey})`
                            : ''}
                    </ActionButton>
                ) : (
                    <ActionButton typeButton={APP_BUTTON_TYPE.DISABLED}>
                        Khảo sát
                    </ActionButton>
                )}

                {data?.numberSaleResult! >= 0 && data?.isFlagLock === 0 ? (
                    <ActionButton
                        typeButton={APP_BUTTON_TYPE.PRIMARY}
                        onClick={() => {
                            goToScreen('/result');
                        }}>
                        Kết quả{' '}
                        {data?.numberSaleResult! > 0
                            ? `(${data?.numberSaleResult})`
                            : ''}
                    </ActionButton>
                ) : (
                    <ActionButton typeButton={APP_BUTTON_TYPE.DISABLED}>
                        Kết quả
                    </ActionButton>
                )}
            </ActionWrapper>
            {data?.isComplete() ? (
                <CompleteWrapper>
                    <FaCircleCheck color="green" size={25} />
                </CompleteWrapper>
            ) : null}
        </NetItemWrapper>
    );
});
