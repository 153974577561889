import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import RootStore from './RootStore';
import {
    IGetHistoryPhoto,
    IGetHistorySaleResult,
    IGetHistorySurvey,
    IGetListHistory,
    IResultVisit,
    IVisitData
} from 'src/types/IVisit';
import { IHistory, ListHistory } from 'src/types/IHistory';
import HistoryService from 'src/services/HistoryService';
import { APIResponse, APIResponseList } from 'src/models/APIResponse';
import { ISurvey } from 'src/types/ISurvey';

class HistoryStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable listHistory: ListHistory | null = null;
    @observable currentHistory: IHistory | null = null;
    @observable historyPhoto: IVisitData | null = null;
    @observable historySurvey: Array<ISurvey> | null = null;
    @observable historyVisitResult: Array<IResultVisit> | null = null;

    @action setCurrentHistoryData = async (data: any) => {
        runInAction(() => {
            this.currentHistory = data;
        });
    };

    @action getListHistory = async (params: IGetListHistory) => {
        const result = (await HistoryService.getInstance().getListHistory(
            params
        )) as any;

        if (result && result.isSuccess())
            runInAction(() => {
                this.listHistory = result.data!;
                console.log(result.data!);
                console.log(toJS(this.listHistory));
            });
        else {
            this.listHistory = null;
        }

        return result;
    };

    @action getHistoryPhoto = async (params: IGetHistoryPhoto) => {
        const result = (await HistoryService.getInstance().getHistoryPhoto(
            params
        )) as APIResponse<IVisitData>;

        if (result && result.isSuccess())
            runInAction(() => {
                this.historyPhoto = result.data!;
            });
        else {
            this.historyPhoto = null;
        }
        return result;
    };

    @action getHistorySurvey = async (params: IGetHistorySurvey) => {
        const result = (await HistoryService.getInstance().getHistorySurvey(
            params
        )) as APIResponseList<Array<ISurvey>>;

        if (result && result.isSuccess())
            runInAction(() => {
                this.historySurvey = result.data?.list!;
            });
        console.log('List history survey', toJS(this.historySurvey));

        return result;
    };

    @action getHistoryVisitResult = async (params: IGetHistorySaleResult) => {
        const result = (await HistoryService.getInstance().getHistorySaleResult(
            params
        )) as APIResponseList<Array<IResultVisit>>;

        if (result && result.isSuccess())
            runInAction(() => {
                this.historyVisitResult = result.data?.list!;
            });
        console.log('List history visit result', toJS(this.historyVisitResult));
        return result;
    };
}
export default HistoryStore;
