import { useRootStore } from 'src/providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';
import { ComponentType, useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { QuestionItem } from './QuestionItem';
import {
    ActionWrapper,
    SurveyContainer,
    SurveyViewWrapper
} from './index.styles';
import { Button, Modal, Spin } from 'antd';
import { IGetListSurveyRequest, ISubmitSurveyRequest } from 'src/types/ISurvey';
import { showMessage } from 'src/utils';
import { NavigationEnum } from 'src/types/INavigation';

//components

//styles

interface Props {}

export const SurveyView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listSurvey, getListSurvey, isDoneSurvey, submitSurvey } =
        store.surveyStore;
    const { currentNetStation } = store.netStationStore;
    const { setAppTitle, changeMainMenuIndex } = store.globalStore;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        onGetListSurvey();
        setAppTitle(currentNetStation?.netName!);
        changeMainMenuIndex(NavigationEnum.Survey);
    }, []);

    const onGetListSurvey = async () => {
        const params: IGetListSurveyRequest = {
            data: {
                netId: currentNetStation?.netId!
            }
        };
        await getListSurvey(params);
    };

    const onCheckSurvey = async () => {
        try {
            setLoading(true);
            if (isDoneSurvey() && listSurvey) {
                const params: ISubmitSurveyRequest = {
                    data: {
                        netId: currentNetStation?.netId!,
                        surveyId: listSurvey[0].surveyId,
                        questList: toJS(listSurvey[0].questList)
                    }
                };
                const result = await submitSurvey(params);
                setLoading(false);
                showMessage(result);
            } else {
                Modal.error({
                    title: 'Không hợp lệ',
                    content: 'Bạn chưa trả lời hết các câu hỏi',
                    centered: true,
                });
            }
            console.log(toJS(listSurvey));
        } catch (error: any) {
            Modal.error({
                title: 'Lỗi xảy ra',
                content: error.message,
                centered: true,
            });
        } finally {setLoading(false);}
    };
    return (
        <SurveyViewWrapper>
            <SurveyContainer>
                {listSurvey &&
                    listSurvey[0].questList.map((question) => {
                        return (
                            <QuestionItem
                                data={question}
                                key={question.questId}
                            />
                        );
                    })}
            </SurveyContainer>
            {loading ? <Spin /> : null}
            <ActionWrapper>
                <Button
                    type="primary"
                    loading={loading}
                    style={{ width: '100%' }}
                    htmlType="button"
                    onClick={onCheckSurvey}>
                    Cập nhật thông tin
                </Button>
            </ActionWrapper>
        </SurveyViewWrapper>
    );
});
