import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';

import {
    IGetListNetStationRequest,
    IGetNetStationInfoRequest,
    INetStation,
    INetStationFilter,
    IUpdateNetStationRequest
} from '../types/INetStation';
import { NetStationService } from '../services/NetStationService';
import { ListNetStation, NetStation } from 'src/models/NetStation';
import { APIResponse, APIResponseList } from 'src/models/APIResponse';
import data from 'src/data/netStation.json';
import { IResponseBase } from 'src/types/ITypeBase';
import {
    ICity,
    IDistrict,
    IGetListCityRequest,
    IGetListDistrictRequest
} from 'src/types/IGlobal';
import { ListCity, ListDistrict } from 'src/models/Location';
import moment from 'moment';
import VisitService from 'src/services/VisitService';

class NetStationStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable listCity: ListCity | null = null;
    @observable listDistrict: ListDistrict | null = null;
    @observable listNetStation: ListNetStation | null = null;
    @observable currentNetStation: INetStation | NetStation | null = null;
    @observable loading: boolean = false;

    @observable netStationFilter: INetStationFilter = {
        netName: '',
        startDate: moment(new Date()),
        endDate: moment(new Date()),
        testDate: moment('10:00:00') //moment(new Date())
    };

    @action updateNetStationFilter = (filter: INetStationFilter) => {
        runInAction(() => {
            this.netStationFilter = filter;
        });
    };

    @action setNetStationData = async (data: any) => {
        runInAction(() => {
            this.currentNetStation = data;
        });
    };

    @action getListNetStation = async (
        params: IGetListNetStationRequest,
        loadMore?: boolean,
        pageIndex?: number
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listNetStation)
            runInAction(() => {
                this.listNetStation = new ListNetStation();
                this.listNetStation.pageIndex = 0;
            });
        else if (loadMore && !pageIndex) {
            this.listNetStation.pageIndex += 1; //load more
        } else if (loadMore && pageIndex !== 0) {
            this.listNetStation.pageIndex = pageIndex! - 1; //paging
        }

        if (this.listNetStation) {
            this.listNetStation.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listNetStation.pageIndex
                : this.listNetStation.pageSize * this.listNetStation.pageIndex;
        }

        //request api service
        const result = (await NetStationService.getInstance().getListNetStation(
            params
        )) as APIResponseList<Array<INetStation>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listNetStation) {
                    if (loadMore && !pageIndex)
                        this.listNetStation.list =
                            this.listNetStation.list?.concat(
                                ListNetStation.fromJson(result.data?.list!)
                            )!;
                    else
                        this.listNetStation.list = ListNetStation.fromJson(
                            result.data?.list!
                        );
                    this.listNetStation.totalRecord = result.totalRecord();
                    this.listNetStation.isLoading = false;
                }
            });
        } else if (this.listNetStation) this.listNetStation.isLoading = false;
        return result;
    };

    @action getNetStationInfo = async (params: IGetNetStationInfoRequest) => {
        this.loading = true;
        const result = (await NetStationService.getInstance().getNetStationInfo(
            params
        )) as APIResponse<INetStation>;

        if (result && result.isSuccess())
            runInAction(() => {
                this.currentNetStation = result.data!;
            });
        this.loading = false;
        return result;
    };

    @action updateNetStation = async (params: IUpdateNetStationRequest) => {
        const result =
            (await NetStationService.getInstance().updateNetStationInfo(
                params
            )) as APIResponse<IResponseBase>;
        return result;
    };

    @action getListCity = async (
        params: IGetListCityRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listCity)
            runInAction(() => {
                this.listCity = new ListCity();
            });
        else if (loadMore) {
            this.listCity.pageIndex += 1;
            this.listCity.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listCity.pageIndex
                : this.listCity.pageSize * this.listCity.pageIndex;
        }

        //request api service
        const result = (await NetStationService.getInstance().getListCity(
            params
        )) as APIResponseList<Array<ICity>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listCity) {
                    this.listCity.list = this.listCity.list?.concat(
                        ListCity.fromJson(result.data?.list!)
                    )!;
                    this.listCity.totalRecord = result.totalRecord();
                    this.listCity.isLoading = false;
                }
            });
        }
        return result;
    };

    @action getListDistrict = async (
        params: IGetListDistrictRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listDistrict)
            runInAction(() => {
                this.listDistrict = new ListDistrict();
            });
        else if (loadMore) {
            this.listDistrict.pageIndex += 1;
            this.listDistrict.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listDistrict.pageIndex
                : this.listDistrict.pageSize * this.listDistrict.pageIndex;
        }

        //request api service
        const result = (await NetStationService.getInstance().getListDistrict(
            params
        )) as APIResponseList<Array<IDistrict>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listDistrict) {
                    this.listDistrict.list = this.listDistrict.list?.concat(
                        ListDistrict.fromJson(result.data?.list!)
                    )!;
                    this.listDistrict.totalRecord = result.totalRecord();
                    this.listDistrict.isLoading = false;
                }
            });
        }
        return result;
    };

    @action uploadLicense = async (params: FormData) => {
        const result = (await VisitService.getInstance().uploadLicense(
            params
        )) as APIResponse<any>;

        return result;
    };

    @action deleteLicense = async (params: any) => {
        const result = (await VisitService.getInstance().deleteLicense(
            params
        )) as APIResponse<any>;

        return result;
    };
}

export default NetStationStore;
