import { device } from 'src/configs/MediaQuery';
import { AppButton } from 'src/styles/AppButton.styles';
import styled from 'styled-components';

export const NetListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    gap: 20px;
    padding-bottom: 150px;
`;

export const NetListTitle = styled.p`
    font-weight: 600;
    font-size: 18px;
`;

export const AddButton = styled(AppButton)`
    @media ${device.mobileS} {
        width: 100%;
    }

    @media ${device.laptop} {
        width: 100%;
    }
    margin-bottom: 20px;
`;

export const SearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
`;

export const SearchButton = styled(AppButton)`
    height: 30px !important;
    font-size: 14px !important;
    margin-left: 10px;
    width: 100%;
`;

export const DateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const DateLabel = styled.p`
    margin: 0;
    margin-bottom: 4px;
`;
