import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useRootStore } from "src/providers/RootStoreProvider";
import { IHistory } from "src/types/IHistory";

interface Props {
    data: IHistory
}
const HistoryItem:FC<Props> = ({data}) => {
    const store = useRootStore();
    const {setCurrentHistoryData} = store.historyStore;

    const navigate = useNavigate();

    const handleClick = () => {
        setCurrentHistoryData(data);
        navigate('/history-detail')
    };
     
    return <div style={{boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px`, padding: 10, display: 'flex', flexDirection: 'column', gap: 5}} onClick={() => handleClick()}>
        <p>VisitID: {data.visitId}</p>
        <p>Ngày visit: {data.createDate}</p>
        <p>Người visit: {data.createBy}</p>
    </div>
}
export default HistoryItem;