import { LOCAL_KEY } from './../configs/AppKey';
import { makeObservable, action, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { IGeoLocation } from '../types/IGlobal';
import { NavigationEnum, NavigationHistoryEnum } from 'src/types/INavigation';

class GlobalStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable language: string | null = null;
    @observable appTitle: string | null = 'CheckIn Tool';
    @observable reload: boolean = false;
    @observable indexMainMenu: NavigationEnum | null = null;
    @observable indexHistoryMenu: NavigationHistoryEnum | null =
        NavigationHistoryEnum.PhotoInfo;

    @observable geoLocation: IGeoLocation | null = null;

    @action setGeoLocationData = (data: IGeoLocation) => {
        runInAction(() => {
            this.geoLocation = data;
        });
    };

    @action setAppTitle = (data: string) => {
        runInAction(() => {
            this.appTitle = data;
        });
    };

    @action setReload = () => {
        runInAction(() => {
            this.reload = !this.reload;
        });
    };

    @action
    changeLanguage = async (language: string) => {
        runInAction(() => {
            this.language = language;
        });
        localStorage.setItem(LOCAL_KEY.USER_LANGUAGE, language);
    };

    @action changeMainMenuIndex = async (index: NavigationEnum) => {
        runInAction(() => {
            this.indexMainMenu = index;
        });
        //localStorage.setItem(LOCAL_KEY.USER_LANGUAGE, language);
    };

    @action changeHistoryMenuIndex = async (index: NavigationHistoryEnum) => {
        runInAction(() => {
            this.indexHistoryMenu = index;
        });
    };
}
export default GlobalStore;
