import { HTTP_STATUS } from './../configs/HttpStatus';
import { AxiosResponse } from 'axios';
import APIHelper from '../helpers/APIHelper';
import { AuthHeader } from '../helpers/AuthHeader';
import {
    ILoginResponseBase,
    IRequestBase,
    IResponseBase,
    IResponseListBase
} from '../types/ITypeBase';
import { getTimestamp, getUUID } from '../utils';
import { API_STATUS } from '../configs/APIStatus';
import BaseService from './BaseService';

class BaseAuthService extends BaseService {
    public constructor() {
        super();
        //override default baseURL and headers of API service
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            //'Content-Type': 'text/json'
            //'Accept-Language': 'vi',
            //'App-Client-Version': process.env.REACT_APP_CLIENT_VERSION!,
            Authorization: 'Bearer ' + AuthHeader()?.accessToken
        };
        APIHelper.defaults.headers.common = headers;
        APIHelper.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    }

    protected postData = (api: string, params: IRequestBase) => {
        params.hvid = AuthHeader()?.userId ?? '';
        params.ssid = AuthHeader()?.ssId ?? '';

        return APIHelper.post(api, params);
    };

    protected getData = (api: string, params: IRequestBase) => {
        return APIHelper.get(api);
    };

    //handles response and error from server
    protected handleResponse = (response: AxiosResponse): IResponseBase => {
        if (response.status != HTTP_STATUS.OK) return null!;

        const result: IResponseBase = {
            data: response.data.data,
            err: response.data.err,
            errTitle: response.data.errTitle,
            errMsg: response.data.errMsg,

            responseCode: function (): number {
                return this.err!;
            },
            isSuccess: function (): boolean {
                return this.err === API_STATUS.OK;
            },
            isError: function (): boolean {
                return this.err != API_STATUS.OK;
            },
            getDescription: function (): string {
                return this.errMsg!;
            },
            getTitle: function (): string {
                return this.errTitle!;
            }
        };

        return result;
    };

    protected handleLoginResponse(
        response: AxiosResponse<any, any>
    ): ILoginResponseBase {
        if (response.status != HTTP_STATUS.OK) return null!;

        const result: ILoginResponseBase = {
            data: response.data.data,
            err: response.data.err,
            errTitle: response.data.errTitle,
            errMsg: response.data.errMsg,

            responseCode: function (): number {
                return this.err!;
            },
            isSuccess: function (): boolean {
                return this.err === API_STATUS.OK;
            },
            isError: function (): boolean {
                return this.err != API_STATUS.OK;
            },
            getTitle: function (): string {
                return this.errTitle!;
            },
            getDescription: function (): string {
                return this.errMsg!;
            }
        };

        //set jwt when login successful
        APIHelper.defaults.headers.common['Authorization'] =
            'Bearer ' + response.data.jwt;

        return result;
    }

    //handles response list data
    protected handleResponseList = (
        response: AxiosResponse
    ): IResponseListBase => {
        if (response.status != HTTP_STATUS.OK) return null!;

        const result: IResponseListBase = {
            //in response list data will be included total record and list data
            //describe by type IDataResponseList<T>
            data: response.data.data,
            err: response.data.err,
            errTitle: response.data.errTitle,
            errMsg: response.data.errMsg,

            responseCode: function (): number {
                return this.err!;
            },
            isSuccess: function (): boolean {
                return this.err === API_STATUS.OK;
            },
            isError: function (): boolean {
                return this.err != API_STATUS.OK;
            },
            getTitle: function (): string {
                return this.errTitle!;
            },
            getDescription: function (): string {
                return this.errMsg!;
            },
            totalRecord: function (): number {
                return response.data.data.total;
            }
        };
        return result;
    };
}

export default BaseAuthService;
