import { ComponentType, useEffect } from 'react';
import pure from 'recompose/pure';
import {
    NavigationHeaderWrapper,
    NavigationSubWrapper,
    NavigationTitle,
    NavigationWrapper,
    TitleWrapper
} from './Navigation.styles';
import { INavigation, NavigationEnum } from 'src/types/INavigation';
import { NavigationItem } from '../NavigationItem';
import { IoHomeOutline } from 'react-icons/io5';
import { LuListChecks } from 'react-icons/lu';
import { FaCarAlt, FaRegCalendarCheck } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'src/providers/RootStoreProvider';
import { FaInfoCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { AuthHeader } from 'src/helpers/AuthHeader';
import { IoIosArrowBack, IoMdLogOut } from 'react-icons/io';

//components

//styles

interface Props {}

export const Navigation: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { logout } = store.authStore;
    const navigate = useNavigate();
    const { currentNetStation } = store.netStationStore;
    const { indexMainMenu, appTitle, geoLocation } = store.globalStore;

    const navigation: Array<INavigation> = [
        
        {
            id: NavigationEnum.Info,
            title: 'Thông tin',
            link: '/info',
            icon: <FaInfoCircle size={30} />
        },
        {
            id: NavigationEnum.Visit,
            title: 'Visit',
            link: '/visit',
            icon: <FaCarAlt size={30} />
        },
        {
            id: NavigationEnum.Survey,
            title: 'Khảo sát',
            link: '/survey',
            icon: <LuListChecks size={30} />
        },
        {
            id: NavigationEnum.Result,
            title: 'Kết quả',
            link: '/result',
            icon: <FaRegCalendarCheck size={30} />
        },
        {
            id: NavigationEnum.History,
            title: 'Lịch sử',
            link: '/history',
            icon: <FaRegCalendarCheck size={30} />
        }
    ];

    useEffect(() => {
        if (
            !geoLocation?.isGeolocationAvailable ||
            !geoLocation.isGeolocationEnabled
        ) {
            console.log("Geolocation is not available");
            navigate('/denied');
        }
        else  if (!AuthHeader()) navigate('/login');
        else if (!currentNetStation) navigate('/net/list');
            
    },[geoLocation]);

    // useEffect(() => {
    //     if (!AuthHeader()) navigate('/login');
    //     else if (!currentNetStation) navigate('/net/list');
    // }, [currentNetStation]);

    const onGoBack = () => {
        navigate('/net/list');
    };

    const onUserLogout = () => {
        navigate('/login');
        logout();
    };

    // return (
    //     <>
    //         {currentNetStation ? (
    //             <NavigationWrapper title="1.0.1">
    //                 {/* {navigation.map((item) => {
    //                     return <NavigationItem data={item} key={item.id} />;
    //                 })} */}
    //                 <IoIosArrowBack
    //                     size={30}
    //                     color="#fff"
    //                     onClick={() => onGoBack()}
    //                 />
    //                 <TitleWrapper>
    //                     <NavigationTitle>{appTitle}</NavigationTitle>
    //                 </TitleWrapper>
    //                 <IoMdLogOut
    //                     onClick={() => onUserLogout()}
    //                     size={30}
    //                     color="#fff"
    //                     style={{ marginRight: 20, cursor: 'pointer' }}
    //                 />
    //             </NavigationWrapper>
    //         ) : (
    //             <NavigationWrapper title="1.0.1">
    //                 <TitleWrapper>
    //                     <NavigationTitle>{appTitle}</NavigationTitle>
    //                 </TitleWrapper>
    //                 <IoMdLogOut
    //                     onClick={() => onUserLogout()}
    //                     size={30}
    //                     color="#fff"
    //                     style={{ marginRight: 20, cursor: 'pointer' }}
    //                 />
    //             </NavigationWrapper>
    //         )}
    //     </>
    // );
    return (
        <NavigationWrapper title="1.0.2">
            <NavigationHeaderWrapper>
                {indexMainMenu !== NavigationEnum.Home ? (
                    <IoIosArrowBack
                        size={30}
                        color="#fff"
                        onClick={() => onGoBack()}
                    />
                ) : (
                    <></>
                )}
                <TitleWrapper>
                    <NavigationTitle>{appTitle}</NavigationTitle>
                </TitleWrapper>
                <IoMdLogOut
                    onClick={() => onUserLogout()}
                    size={30}
                    color="#fff"
                    style={{ marginRight: 20, cursor: 'pointer' }}
                />
            </NavigationHeaderWrapper>

            {indexMainMenu !== NavigationEnum.Home ? 
            <NavigationSubWrapper>
                {navigation.map((item) => {
                    return <NavigationItem data={item} key={item.id} />;
                })}
            </NavigationSubWrapper> : null}
        </NavigationWrapper>
    );
});
