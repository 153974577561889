import styled from 'styled-components';

export const LocationWrapper = styled.div`
    margin: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
`;

export const ErrorTitle = styled.p`
    font-size: 18px;
    font-weight: 600;
`;

export const ErrorDescription = styled.p`
    font-size: 14px;
`;
