import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { IGetVisitInfoRequest, IVisitData } from 'src/types/IVisit';
import data from 'src/data/visit.json';
import VisitService from 'src/services/VisitService';
import { APIResponse } from 'src/models/APIResponse';

class VisitStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable visitData: IVisitData | null = null;

    @action setVisitData = async () => {
        runInAction(() => {
            this.visitData = data.data;
        });
    };

    @action resetVisitData = async () => {
        runInAction(() => {
            this.visitData = null;
        });
    };

    @action getVisitInfo = async (params: IGetVisitInfoRequest) => {
        this.resetVisitData();
        const result = (await VisitService.getInstance().getVisitInfo(
            params
        )) as APIResponse<IVisitData>;

        if (result && result.isSuccess())
            runInAction(() => {
                this.visitData = result.data!;
            });
        else {
            this.visitData = null;
            console.log('visitData null');
        }

        return result;
    };

    @action updateVisitInfo = async (params: FormData) => {
        const result = (await VisitService.getInstance().updateVisitInfo(
            params
        )) as APIResponse<any>;

        return result;
    };
}
export default VisitStore;
