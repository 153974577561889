import { observer } from 'mobx-react-lite';
import { useRootStore } from 'src/providers/RootStoreProvider';

const PhotoInfo = observer(({}) => {
    const store = useRootStore();
    const { historyPhoto } = store.historyStore;

    return (
        <div style={{ margin: 10 }}>
            <div>
                <p>Longitude: {historyPhoto?.location.longitude}</p>
                <p>Latitude: {historyPhoto?.location.latitude}</p>
                <p>Ghi chú: {historyPhoto?.note}</p>
            </div>
            <div>
                <p style={{fontWeight: 'bold'}}>Hình ảnh:</p>
                <div style={{display: 'flex', flexDirection: 'column', gap: 15}}>
                    {historyPhoto?.fileList.map((item) => {
                        return (
                            <img
                                key={item.uid}
                                src={item.url}
                                alt=''
                                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
});
export default PhotoInfo;
