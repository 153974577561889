import { Button } from 'antd';
import { AppButton } from 'src/styles/AppButton.styles';
import styled from 'styled-components';

export const NetItemWrapper = styled.div`
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    height: 140px;
    width: 100%;
    padding: 10px;
    position: relative;
`;

export const CompleteWrapper = styled.div`
    display: flex;
    position: absolute;
    right: 10px;
    top: 10px;
`;

export const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
`;

export const NetName = styled.p`
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: #595757;
    margin-bottom: 8px;
`;

export const NetAddress = styled.p`
    margin: 0;
    font-size: 14px;
    color: #8c8a89;
    margin-left: 10px;
`;

export const NetPhone = styled.p`
    margin: 0;
    font-size: 14px;
    color: #8c8a89;
    margin-left: 10px;
`;

export const ActionWrapper = styled.div`
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
`;

export const ActionButton = styled(AppButton)`
    height: 30px !important;
    width: 84px !important;
    font-size: 10px !important;
    font-weight: 400 !important;
`;
