import styled from 'styled-components';

export const AnswerItemContainer = styled.div`
    display: flex;
    margin: 10px;
    cursor: pointer;
`;

export const AnswerItemInput = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AnswerName = styled.p`
    font-size: 16px;
    margin-left: 15px;
`;

interface AnswerCheckProps {
    isChecked: boolean;
}

export const AnswerCheck = styled.div.attrs((props: AnswerCheckProps) => ({
    className: props.isChecked
}))`
    display: inline-block;
    height: 25px;
    width: 12px;
    background-color: #e0e0de;
    &.checked {
        transform: rotate(45deg);
        border-bottom: 7px solid #78b13f;
        border-right: 7px solid #78b13f;
    }

    &.none {
        border: solid 1px #6e6c67;
        width: 25px;
        border-radius: 4px;
    }
`;
