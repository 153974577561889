import { INetStation, IPhotoLicense } from '../types/INetStation';
import { DataList } from './DataList';

export class NetStation {
    public netId: number;
    public netName: string;
    public phone: string;
    public address: string;
    public cityId: number;
    public cityName: string;
    public districtId: number;
    public districtName: string;
    public ownerName: string;
    public ownerPhone: string;
    public ownerEmail: string;
    public managerName: string;
    public managerPhone: string;
    public techniqueName: string;
    public techniquePhone: string;
    public numberSaleResult: number;
    public numberVisit: number;
    public numberSurvey: number;
    public isFlagLock: number;
    public images: IPhotoLicense[];

    constructor(
        netId: number,
        netName: string,
        phone: string,
        address: string,
        cityId: number,
        cityName: string,
        districtId: number,
        districtName: string,
        ownerName: string,
        ownerPhone: string,
        ownerEmail: string,
        managerName: string,
        managerPhone: string,
        techniqueName: string,
        techniquePhone: string,
        numberSaleResult: number,
        numberVisit: number,
        numberSurvey: number,
        isFlagLock: number,
        images: IPhotoLicense[]
    ) {
        this.netId = netId;
        this.netName = netName;
        this.phone = phone;
        this.address = address;
        this.cityId = cityId;
        this.cityName = cityName;
        this.districtId = districtId;
        this.districtName = districtName;
        this.ownerName = ownerName;
        this.ownerPhone = ownerPhone;
        this.ownerEmail = ownerEmail;
        this.managerName = managerName;
        this.managerPhone = managerPhone;
        this.techniqueName = techniqueName;
        this.techniquePhone = techniquePhone;
        this.numberSaleResult = numberSaleResult;
        this.numberVisit = numberVisit;
        this.numberSurvey = numberSurvey;
        this.isFlagLock = isFlagLock;
        this.images = images;
    }

    static fromJson = (json: INetStation): NetStation => {
        return new NetStation(
            json.netId,
            json.netName!,
            json.phone!,
            json.address!,
            json.cityId!,
            json.cityName!,
            json.districtId!,
            json.districtName!,
            json.ownerName!,
            json.ownerPhone!,
            json.ownerEmail!,
            json.managerName!,
            json.managerPhone!,
            json.techniqueName!,
            json.techniquePhone!,
            json.numberSaleResult!,
            json.numberVisit!,
            json.numberSurvey!,
            json.isFlagLock!,
            json.images!
        );
    };

    isComplete = (): boolean => {
        return (
            this.numberSaleResult > 0 &&
            this.numberSurvey > 0 &&
            this.numberVisit > 0
        );
    };
}

export class ListNetStation extends DataList<NetStation> {
    static fromJson = (jsonArray: Array<INetStation>): Array<NetStation> => {
        if (!jsonArray) return [];
        let data = new Array<NetStation>();
        jsonArray.forEach((item) => {
            data.push(NetStation.fromJson(item));
        });
        return data;
    };

    hasData(): boolean {
        return this.list.length > 0;
    }
}
