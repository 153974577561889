import { ComponentType } from 'react';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { Outlet } from 'react-router-dom';
import 'react-tiny-fab/dist/styles.css';

import { useRootStore } from '../../providers/RootStoreProvider';
import { observer } from 'mobx-react-lite';
// define interface to represent component props
interface Props {}

const MainLayout: ComponentType<Props> = observer(() => {
    const store = useRootStore();
    const { geoLocation } = store.globalStore;

    console.log('geoLocation', geoLocation);

    return (
        <div>
            <Header></Header>
            <div style={{ marginTop: '110px' }}>
                <Outlet />
            </div>
            <Footer />
        </div>
    );
});

export default MainLayout;
