import { IGetVisitInfoRequest } from 'src/types/IVisit';
import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import BaseUploadService from './BaseUploadService';

class VisitService extends BaseUploadService {
    private static instance: VisitService;

    public static getInstance(): VisitService {
        if (!VisitService.instance) {
            VisitService.instance = new VisitService();
        }
        return VisitService.instance;
    }

    getVisitInfo = async (params: IGetVisitInfoRequest) => {
        try {
            const response = await this.getData(API.NET_STATION.PHOTO, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    updateVisitInfo = async (params: FormData) => {
        try {
            const response = await this.postData(
                API.NET_STATION.UPDATE_PHOTO,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    uploadLicense = async (params: FormData) => {
        try {
            const response = await this.postData(
                API.NET_STATION.PHOTO_LICENSE,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    deleteLicense = async (image: any) => {
        try {
            const response = await this.postData(
                API.NET_STATION.DELETE_LICENSE,
                image
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}

export default VisitService;
