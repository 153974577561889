import { v4 } from 'uuid';
import { Buffer } from 'buffer';
import { IResponseBase } from '../types/ITypeBase';
import { Modal } from 'antd';
import { IAnswer, IQuestion } from 'src/types/ISurvey';
import _ from 'lodash';

export function getUUID() {
    return v4().toString();
}

export function getTimestamp() {
    return Math.floor(Date.now() / 1000).toString();
}

export function stripedHtml(val: any) {
    if (!val) return null;
    val = val.replace(/<[^>]+>/g, '');
    //val = val.replace(/[^a-zA-Z0-9,/ ]/g, '');
    return val;
}

export function setPropertyValue(
    obj: any,
    propertyName: string,
    newValue: any
) {
    if (obj.hasOwnProperty(propertyName)) {
        obj[propertyName] = newValue;
    } else {
    }
}

export function isAnswerValid(question: IQuestion) {
    let result: boolean = false;
    _.some(question.answerDetails, (answer: IAnswer) => {
        if (answer.selected || answer.extInput) {
            result = true;
            return true;
        }
    });
    return result;
}

export function showMessage(
    result: IResponseBase,
    defaultSuccessMsg?: string,
    defaultFailureMsg?: string
) {
    if (result && result.isSuccess()) {
        return Modal.success({
            title:
                result.getTitle() && result.getTitle().length > 0
                    ? result.getTitle()
                    : 'Thành công',
            content:
                result.getDescription() && result.getDescription().length > 0
                    ? result.getDescription()
                    : defaultSuccessMsg && defaultSuccessMsg.length > 0
                    ? defaultSuccessMsg
                    : 'Thao tác thành công',
            centered: true
        });
    } else {
        return Modal.error({
            title:
                result.getTitle() && result.getTitle().length > 0
                    ? result.getTitle()
                    : `Thất bại. Mã lỗi ${result.responseCode()}`,
            content:
                result.getDescription() && result.getDescription().length > 0
                    ? result.getDescription()
                    : defaultFailureMsg && defaultFailureMsg.length > 0
                    ? defaultFailureMsg
                    : 'Có lỗi xảy ra. Vui lòng thử lại hoặc liên hệ với bộ phận chăm sóc khách hàng của chúng tôi',
            centered: true
        });
    }
}
