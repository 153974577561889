import { ComponentType, useEffect } from "react";
import { useRootStore } from "src/providers/RootStoreProvider";
import { IGetListHistory } from "src/types/IVisit";
import HistoryItem from "./HistoryIten";
import { observer } from "mobx-react-lite";
import { NavigationEnum } from "src/types/INavigation";

interface Props {}

const HistoryView: ComponentType<Props> =  observer(({}) => {
    const store = useRootStore();
    const {currentNetStation} = store.netStationStore;
    const { listHistory, getListHistory} = store.historyStore;
    const { setAppTitle, changeMainMenuIndex } = store.globalStore;

    const onGetListHistory = async () => {
        const params: IGetListHistory = {
            data: {
                netId: currentNetStation?.netId!
            }
        };
        await getListHistory(params);
    };

    useEffect(() => {
        setAppTitle('Lịch sử visit');
        changeMainMenuIndex(NavigationEnum.History);
        if(currentNetStation) onGetListHistory();
    },[currentNetStation]);

    return <div style={{margin: 10, gap: 10, display: 'flex', flexDirection: 'column', marginTop: 150}}>
        {listHistory && listHistory.list.map((item) => {
            return <HistoryItem key={item.visitId} data={item} />
        })}
    </div>
});
export default HistoryView;