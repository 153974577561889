import {
    IGetHistoryPhoto,
    IGetHistorySaleResult,
    IGetHistorySurvey,
    IGetListHistory
} from 'src/types/IVisit';
import { API } from '../configs/APIEndPoint';
import { handleError } from '../helpers/APIHelper';
import BaseAuthService from './BaseAuthService';

class HistoryService extends BaseAuthService {
    private static instance: HistoryService;

    public static getInstance(): HistoryService {
        if (!HistoryService.instance) {
            HistoryService.instance = new HistoryService();
        }
        return HistoryService.instance;
    }

    getListHistory = async (params: IGetListHistory) => {
        try {
            const response = await this.postData(API.HISTORY.LIST, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getHistoryPhoto = async (params: IGetHistoryPhoto) => {
        try {
            const response = await this.postData(API.HISTORY.PHOTO, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getHistorySaleResult = async (params: IGetHistorySaleResult) => {
        try {
            const response = await this.postData(
                API.HISTORY.SALE_RESULT,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    getHistorySurvey = async (params: IGetHistorySurvey) => {
        try {
            const response = await this.postData(API.HISTORY.SURVEY, params);
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}

export default HistoryService;
